import React from "react";
import { Box, Button } from '@mui/material';
interface ModalButtonProps {
    contentDirection: string
    toggleModal: () => void
    text: string
}
const ModalButton = ({ contentDirection, toggleModal, text }: ModalButtonProps) => {
    return (
        <Box display="flex" justifyContent={contentDirection} gap={2} mt={3}>
            <Button onClick={toggleModal} variant="contained" sx={{
                backgroundColor: '#1A4A421A',
                color: '#1A4A4299',
                padding: '1rem 0.8rem',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                fontSize: '1.2rem',
                width: '10rem',
                '&:hover': {
                    backgroundColor: '#F5FEFD',
                }
            }}>
                Cancel
            </Button>
            <Button type="submit" variant="contained" sx={{
                backgroundColor: '#1A4A42',
                color: 'white',
                padding: '1rem 0.8rem',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                fontSize: '1.2rem',
                width: '10rem',
                '&:hover': {
                    backgroundColor: '#1B5C54',
                }
            }}>
                {text}
            </Button>
        </Box>
    )
}
export default ModalButton