import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { DealType, generatePipeline, PipelineType, StagesDataType, UserType, generateRandomUsers } from "../../cfpipelines1/src/utils/generatePipeline";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from 'dayjs'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    deal_name: string
    stageId: string
    due_date: Dayjs | string
    assigned_to: UserType
    selectedPriority: string
    users: UserType[]
    singleStage: StagesDataType
    description: string
    asso_pipeline: string
    singleDeal: DealType
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreateCfDealController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            asso_pipeline: '',
            singleDeal: { deal_id: '', deal_name: '', deal_desc: '', due_date: '', priority: '', created_at: '', asso_user: { source: '', user_name: '' }, pipeline_id: '', stage_id: '' },
            users: generateRandomUsers(10),
            assigned_to: { source: '', user_name: '' },
            due_date: dayjs(new Date()),
            singleStage: { id: '', created_at: '', permission_level: '', pipeline_id: "", deals: [], description: '', rule_builder_id: null, stage_name: '', task: null, updated_at: '' },
            stageId: '',
            selectedPriority: '',
            deal_name: '',
            description: '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        const { stageId, pipelineId } = Object.fromEntries(new URLSearchParams(window.location.search));
        this.setState({ asso_pipeline: pipelineId, stageId: stageId })
    }

    handleChangeCreateDeal = (event: any) => {
        const { value, name } = event.target;
        this.setState((prevState) =>({...prevState,[name]:value}))
    }

    handleCreateDeal = () => {
        const { deal_name, due_date, assigned_to, asso_pipeline, description, selectedPriority, singleDeal, stageId } = this.state
        const allPipelineData: PipelineType[] = generatePipeline()
        const pipelineData = allPipelineData.find(pipeline => pipeline.id == asso_pipeline)
        let stageData;
        if (pipelineData) {
            stageData = pipelineData.stages.find(stage => stage.id == stageId)
            if(!stageData){
            stageData= {deals: Array(10).fill(1)}
            }
        }else{
            stageData= {deals: Array(10).fill(1)}
        }
        const updatedDeal = { ...singleDeal };
        updatedDeal.deal_id = `#D000000${stageData?.deals.length}`
        updatedDeal.deal_name = deal_name
        updatedDeal.deal_desc = description
        updatedDeal.priority = selectedPriority
        updatedDeal.due_date = due_date
        updatedDeal.asso_user = assigned_to
        updatedDeal.stage_id = stageId
        updatedDeal.pipeline_id = asso_pipeline
        updatedDeal.created_at = new Date().toISOString()
        this.setState({ singleDeal: updatedDeal })
        stageData?.deals.push(updatedDeal)
        localStorage.setItem("deal", JSON.stringify(updatedDeal))
        toast.success("Deal Created successfully", { style: { fontSize: '1.6rem' } })
        this.props.navigation.navigate('CfDealDetails', { dealId: updatedDeal.deal_id, stageId: updatedDeal.deal_id, pipelineId: updatedDeal.pipeline_id })
    }

    isCreateDealFormComplete = () => {
        const { deal_name, description, asso_pipeline, due_date, stageId, assigned_to, selectedPriority } = this.state;
        return (
            asso_pipeline && deal_name &&
            description && due_date && stageId && assigned_to && selectedPriority
        );
    };

    handleCreateDealPriorityChange = (selectedPriority: string) => {
        this.setState({ selectedPriority })
    }

    handleCreateDealDayChange = (newValue: any) => {
        this.setState({ due_date: newValue })
    }
    handleCreateAssignedChanged = (user: UserType) => {
        this.setState({ assigned_to: user })
    }
    // Customizable Area End
}
