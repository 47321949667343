import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface StagesType {
  label: string,
  value: string,
}
interface SelectedOption {
  label: string,
  value: string,
  id: number
}
interface EventsType {
  id: number | null,
  event_name: string,
  dropdown: string,
  value: string,
}
interface TriggersType {
  id: number | null,
  trigger_name: string,
  selection1: string,
  value1: string,
  selection2: string,
  value2: string,
}
type PipelineResponseType = {
  id: string;
  type: string;
  attributes: {
    pipeline_name: string;
    stages: StageType[];
    description: string;
    status: string | null;
    permission_level: string;
  };
};
type StageType = {
  id: number;
  stage_name: string;
  description: string;
  task: any;
  permission_level: string;
  pipeline_id: number;
  rule_builder_id: number | null;
};
import { Dayjs } from 'dayjs';
import { toast } from "react-toastify";

const commonNumberFieldSelect = [
  { label: 'Equal to', value: 'equal_to' },
  { label: 'Not equal to', value: 'not_equal_to' },
  { label: 'Greater Than', value: 'greater_than' },
  { label: 'Less than', value: 'less_than' },
  { label: 'Greater than or Equal to', value: 'greater_than_or_equal_to' },
  { label: 'Less than or Equal to', value: 'less_than_or_equal_to' },
  { label: 'Is between', value: 'is_between' },
  { label: 'ANY', value: 'any' },
]

const commonDateFieldSelect = [
  { label: 'Date Is', value: 'date_is' },
  { label: 'Date Is Before', value: 'date_is_before' },
  { label: 'Date is on or before', value: 'date_is_on_or_before' },
  { label: 'Date is on or after', value: 'date_is_on_or_after' },
  { label: 'Date is Between', value: 'date_is_between' },
  { label: 'Date is not between', value: 'date_is_not_between' },
  { label: 'ANY', value: 'any' },
]

const commonReminderTypeSelect = [
  { label: 'Today', value: 'today' },
  { label: 'Approaching', value: 'approaching' },
  { label: 'Overdue', value: 'overdue' },
]
const triggerSelectionPart1 = [
  { label: 'Terms and Conditions Signed', value: 'terms_and_conditions_signed' },
  { label: 'Consent Form Signed', value: 'consent_form_signed' },
  { label: 'Consent Form', value: 'consent_form' },
  { label: 'Stripe ID', value: 'stripe_id' },
  { label: 'Patient Tag', value: 'patient_tag' },
  { label: 'Primary Insurance Name', value: 'primary_insurance_name' },
  { label: 'Primary Insurance ID', value: 'primary_insurance_id' },
  { label: 'Secondary Insurance Name', value: 'secondary_insurance_name' },
  { label: 'Secondary Insurance ID', value: 'secondary_insurance_id' },
  { label: 'Copay', value: 'copay' },
  { label: 'Delay Index', value: 'delay_index' },
  { label: 'Full State Name', value: 'full_state_name' },
  { label: 'Full Name', value: 'full_name' },
  { label: 'First Provider Name', value: 'first_provider_name' },
  { label: 'Current Provider Name', value: 'current_provider_name' },
  { label: 'Potentially Active', value: 'potentially_active' },
  { label: 'Total Sessions', value: 'total_sessions' },
  { label: 'Switched Provider', value: 'switched_provider' },
  { label: 'Sessions Paid Out', value: 'sessions_paid_out' },
  { label: 'Start Date', value: 'start_date' },
  { label: 'Second Session', value: 'second_session' },
  { label: 'Latest Date', value: 'latest_date' },
  { label: 'Days Retained', value: 'days_retained' },
  { label: 'Delay Between First and Second Session', value: 'delay_between_first_and_second_session' }
]
const triggerSelectionPart2 = [
  {
    label: 'Months Since Cohort Start',
    value: 'months_since_cohort_start',
  },
  { label: 'Initial Sessions', value: 'initial_sessions' },
  { label: 'Full Address', value: 'full_address' },
  { label: 'Activation Status', value: 'activation_status' },
  { label: 'Next Session', value: 'next_session' },
  { label: 'Name CC Booked', value: 'name_cc_booked' },
  { label: 'Profit Per Session', value: 'profit_per_session' },
  { label: 'Age', value: 'age' },
  { label: 'Incomplete', value: 'incomplete' },
  { label: 'Provider ID', value: 'provider_id' },
  { label: 'Auth Rep ID', value: 'auth_rep_id' },
  { label: 'P-Verify ID', value: 'p_verify_id' },
  {
    label: 'Patient Custom Information ID',
    value: 'patient_custom_information_id',
  },
  { label: 'Session ID', value: 'session_id' },
]
const dealMoveTypeSelection = [
  { label: 'To', value: 'to' },
  { label: 'From', value: 'from' },
  { label: 'ANY', value: 'any' },
]
const dealTypeSelection = [{ label: 'Task Deal', value: 'task_deal' }, { label: 'Provider Deal', value: 'provider_deal' }, { label: 'Client Deal', value: 'client_deal' }]
const fieldListSelection = [
  { label: 'Patient', value: 'patient' },
  { label: 'Provider', value: 'provider' },
  { label: 'ANY', value: 'any' },
]
const triggerSelectionOptions = [
  {
    label: 'Field is changed',
    value: 'field_is_changed',
    select1: fieldListSelection,
    input1: [
      { label: 'Task Name', value: 'task_name' },
      { label: 'Description', value: 'description' },
      { label: 'Due Date', value: 'due_date' },
      { label: 'Start Date', value: 'start_date' },
      { label: 'Project', value: 'project' },
      { label: 'Section', value: 'section' },
      { label: 'Priority', value: 'priority' },
      { label: 'Tags', value: 'tags' },
      { label: 'Attachments', value: 'attachments' },
      { label: 'Subtasks', value: 'subtasks' },
      { label: 'Comments', value: 'comments' },
      { label: 'Custom Fields', value: 'custom_fields' },
      { label: 'Dependencies', value: 'dependencies' },
      { label: 'Collaborators', value: 'collaborators' },
      { label: 'Milestones', value: 'milestones' },
      { label: 'Due Time', value: 'due_time' },
      { label: 'Customer Name', value: 'customer_name' },
      { label: 'Email Address', value: 'email_address' },
      { label: 'Phone Number', value: 'phone_number' },
      { label: 'Company Name', value: 'company_name' },
      { label: 'Lead Source', value: 'lead_source' },
      { label: 'Lead Status', value: 'lead_status' },
      { label: 'Deal Size', value: 'deal_size' },
      { label: 'Close Date', value: 'close_date' },
      { label: 'Last Contact Date', value: 'last_contact_date' },
      { label: 'Next Follow-Up Date', value: 'next_follow_up_date' },
      { label: 'Communication History', value: 'communication_history' },
      { label: 'Pipeline Stage', value: 'pipeline_stage' },
      { label: 'Patient ID', value: 'patient_id' },
      { label: 'First Name', value: 'first_name' },
      { label: 'Last Name', value: 'last_name' },
      { label: 'Cell Number', value: 'cell_number' },
      { label: 'Landline Number', value: 'landline_number' },
      { label: 'Email', value: 'email' },
      { label: 'Address Line 1', value: 'address_line_1' },
      { label: 'Address Line 2', value: 'address_line_2' },
      { label: 'Address City', value: 'address_city' },
      { label: 'Address Country', value: 'address_country' },
      { label: 'Address State', value: 'address_state' },
      { label: 'Address ZIP', value: 'address_zip' },
      { label: 'DOB', value: 'dob' },
      { label: 'Gender', value: 'gender' },
      { label: 'Preferred Pronouns', value: 'preferred_pronouns' },
      { label: 'Race', value: 'race' },
      { label: 'Relationship Status', value: 'relationship_status' },
      { label: 'Living Status', value: 'living_status' },
      { label: 'First Time Therapy', value: 'first_time_therapy' },
      { label: 'Awareness Channel', value: 'awareness_channel' },
      ...triggerSelectionPart1,
      ...triggerSelectionPart2,
    ],
    select2: [
      { label: 'Contains', value: 'contains' },
      { label: 'Does not contain', value: 'does_not_contain' },
      { label: 'Is Exactly', value: 'is_exactly' },
      { label: 'Starts with', value: 'starts_with' },
      { label: 'Ends with', value: 'ends_with' },
      { label: 'ANY', value: 'any' },
    ],
  },
  {
    label: 'Session is Booked',
    value: 'session_is_booked',
    select1: commonNumberFieldSelect,
    input1: [{ label: '778888', value: '88878' }],
    select2: commonDateFieldSelect,
  },
  {
    label: 'Session Occurs',
    value: 'session_occurs',
    select1: commonNumberFieldSelect,
    input1: [{ label: '778888', value: '88878' }],
    select2: commonDateFieldSelect,
  },
  {
    label: 'Appointment Is',
    value: 'appointment_is',
    select1: commonNumberFieldSelect,
    input1: [{ label: '778888', value: '88878' }],
    select2: commonDateFieldSelect,
  },
  {
    label: 'Deal is Moved',
    value: 'deal_is_moved',
    select1: dealMoveTypeSelection,
    input1: [
      {
        label: 'Dynamic stage drop down list from existing stages',
        value: 'dynamic_stage_drop_down_list_from_existing_stages',
      },
    ],
    select2: [{ label: '----', value: '----' }],
  },
  {
    label: 'Deal is Assigned',
    value: 'deal_is_assigned',
    select1: [
      { label: 'CC', value: 'cc' },
      { label: 'Task List', value: 'task_list' },
    ],
    input1: [
      {
        label: "Dynamic list of CC's or Task lists",
        value: "dynamic_list_of_cc's_or_task_lists",
      },
    ],
    select2: commonReminderTypeSelect,
  },
  {
    label: 'Due Date',
    value: 'due_date',
    select1: commonDateFieldSelect,
    input1: [{ label: '01/01/2024', value: '01/01/2024' }],
    select2: commonReminderTypeSelect,
  },
];

const eventSelectionOptions = [
  {
    label: 'Assign to a CC / Task List',
    value: 'assign_to_a_cc_task_list',
    select: [
      { label: 'CC', value: 'cc' },
      { label: 'Task List', value: 'task_list' },

    ],
    input: [
      {
        label: 'List of CCs',
        value: 'list_of_ccs',
      },
      {
        label: 'List of Tasks',
        value: 'list_of_tasks',
      },
    ],
  },
  {
    label: 'Create Deal',
    value: 'create_deal',
    select: dealTypeSelection,
    input: [{ label: 'Deal Details', value: 'deal_details' }],
  },
  {
    label: 'Move Deal',
    value: 'move_deal',
    select: dealMoveTypeSelection,
    input: [{ label: 'Stage', value: 'stage' }],
  },
  {
    label: 'Set a Due Date',
    value: 'set_a_due_date',
    select: commonDateFieldSelect,
    input: [{ label: 'Date (with validation)', value: 'date_with_validation' }],
  },
  {
    label: 'Send Email/SMS',
    value: 'send_email_sms',
    select: [
      { label: 'Email/SMS Template List', value: 'email_sms_template_list' },
    ],
    input: [
      {
        label: 'Customize (Subject Line and Body)',
        value: 'customize_subject_line_and_body',
      },
    ],
  },
  {
    label: 'Set/Change Priority',
    value: 'set_change_priority',
    select: [{ label: '---', value: '---' }],
    input: [{ label: 'Priority Level (List)', value: 'priority_level_list' }],
  },
  {
    label: 'Set a Reminder',
    value: 'set_a_reminder',
    select: commonDateFieldSelect,
    input: [
      {
        label: 'Date and Time (with validation)',
        value: 'date_and_time_with_validation',
      },
    ],
  },
  {
    label: 'Mark Deal as Complete',
    value: 'mark_deal_as_complete',
    select: dealTypeSelection,
    input: [
      { label: 'Complete', value: 'complete' },
      { label: 'Incomplete', value: 'incomplete' },
    ],
  },
  {
    label: 'Change a Field',
    value: 'change_a_field',
    select: fieldListSelection,
    input: [{ label: 'Field', value: 'field' }],
  },
  {
    label: 'Cancel Appointment',
    value: 'cancel_appointment',
    select: [{ label: '---', value: '---' }],
    input: [{ label: '---', value: '---' }],
  },
];

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rule_name: string
  pipeline_name: string
  selectedStages: StageType[]
  selectedTriggers: SelectedOption[]
  selectedEvents: SelectedOption[]
  after: Dayjs | null;
  triggerType: string
  delay1: string,
  delay2: string,
  addTriggerModal: boolean,
  addEventModal: boolean,
  editTriggerModal: boolean,
  editEventModal: boolean,
  triggerName: string,
  editTriggerName: string,
  triggerSelect1: string,
  editTriggerSelect1: string,
  triggerInput1: string,
  editTriggerInput1: string
  triggerSelect2: string,
  editTriggerSelect2: string
  triggerInput2: string,
  editTriggerInput2: string,
  eventName: string,
  editEventName: string,
  eventSelect: string,
  editEventSelect: string,
  eventInput: string
  editEventInput: string
  pipelineData: PipelineResponseType,
  stages: StageType[],
  pipeline_id: string | null
  triggersData: TriggersType[]
  eventsData: EventsType[]
  tsel1data: StagesType[],
  tsel2sata: StagesType[],
  tinput1Data: StagesType[],
  tinput2Data: StagesType[],
  esel1Data: StagesType[],
  einput1Data: StagesType[],
  isTriggerSelect1Enabled: boolean,
  isTriggerInput1Enabled: boolean,
  isTriggerSelect2Enabled: boolean,
  isTriggerInput2Enabled: boolean,
  isEventSelectEnabled: boolean,
  isEventInputEnabled: boolean,
  editTriggerPrevValue: TriggersType,
  editEventPrevValue: EventsType
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreatePipelineRuleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pipelineDataApiCallId: string = ''
  triggersDataApiCallId: string = ''
  eventsDataApiCallId: string = ''
  createTriggerApiId: string = "";
  createEventApiId: string = "";
  editTriggerApiId: string = '';
  editEventApiId: string = '';
  createRuleAPiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      rule_name: '',
      pipeline_name: '',
      selectedStages: [],
      selectedTriggers: [],
      selectedEvents: [],
      after: null,
      triggerType: '',
      delay1: "",
      delay2: "",
      addTriggerModal: false,
      addEventModal: false,
      editTriggerModal: false,
      editEventModal: false,
      triggerName: '',
      editTriggerName: '',
      triggerSelect1: '',
      editTriggerSelect1: '',
      triggerInput1: '',
      editTriggerInput1: '',
      triggerSelect2: '',
      editTriggerSelect2: '',
      triggerInput2: '',
      editTriggerInput2: '',
      eventName: '',
      editEventName: '',
      eventSelect: '',
      editEventSelect: '',
      eventInput: '',
      editEventInput: '',
      pipelineData: { id: '', type: '', attributes: { description: '', permission_level: '', pipeline_name: '', stages: [], status: '' } },
      stages: [],
      pipeline_id: null,
      triggersData: [],
      eventsData: [],
      tsel1data: [],
      tsel2sata: [],
      tinput1Data: [],
      tinput2Data: [],
      esel1Data: [],
      einput1Data: [],
      isTriggerSelect1Enabled: false,
      isTriggerInput1Enabled: false,
      isTriggerSelect2Enabled: false,
      isTriggerInput2Enabled: false,
      isEventSelectEnabled: false,
      isEventInputEnabled: false,
      editTriggerPrevValue: { id: null, selection1: '', selection2: '', trigger_name: '', value1: '', value2: '' },
      editEventPrevValue: { id: null, dropdown: '', event_name: '', value: '' },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.getPipelineDataValue(message)
    this.getAllTriggersData(message)
    this.getAllEventsData(message)
    this.getEventCreatedData(message)
    this.getTriggerCreatedData(message)
    this.getEditedTriggerDataValue(message)
    this.getEditedEventDataValue(message)
    this.getRuleBuilderCreatedData(message)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    this.getPipeline_IdFromUrl()
    this.getAllTriggersApiCall()
    this.getAllEventsApiCall()
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.pipeline_id !== this.state.pipeline_id) {
      this.getPipelineDataApiCall()
    }
    if (prevState.triggerName !== this.state.triggerName) {
      const currentData = triggerSelectionOptions.find(option => option.value == this.state.triggerName)
      if (currentData) {
        this.setState({
          tsel1data: currentData.select1,
          tsel2sata: currentData.select2,
          tinput1Data: currentData.input1
        });
      }
    }
    if (prevState.editTriggerName !== this.state.editTriggerName) {
      const currentTriggerData = triggerSelectionOptions.find(option => option.value == this.state.editTriggerName)
      if (currentTriggerData) {
        this.setState({
          tsel1data: currentTriggerData.select1,
          tsel2sata: currentTriggerData.select2,
          tinput1Data: currentTriggerData.input1
        });
      }
    }
    if (prevState.eventName !== this.state.eventName) {
      const eventSubField = eventSelectionOptions.find(option => option.value == this.state.eventName)
      if (eventSubField) {
        this.setState({
          esel1Data: eventSubField.select,
          einput1Data: eventSubField.input,
        });
      }
    }
    if (prevState.editEventName !== this.state.editEventName) {
      const editEventSubField = eventSelectionOptions.find(option => option.value == this.state.editEventName)
      if (editEventSubField) {
        this.setState({
          esel1Data: editEventSubField.select,
          einput1Data: editEventSubField.input,
        });
      }
    }
  }
  getPipeline_IdFromUrl = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const pipeline_id = queryParams.get('pipelineId');
    this.setState({ pipeline_id });
  }

  getPipelineDataApiCall = () => {
    let tokenValue = "";
    if (typeof localStorage !== 'undefined') {
      tokenValue = localStorage.getItem("token") || "";
    }
    const PCheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokenValue
    };
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.pipelineDataApiCallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPipelineDataEndPoint}/${this.state.pipeline_id}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  getPipelineDataValue(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.pipelineDataApiCallId) {
        if (responseJson && responseJson.errors) {
          this.setState({ pipelineData: { id: '', type: '', attributes: { description: '', permission_level: '', pipeline_name: '', stages: [], status: '' } }, });
        } else if (responseJson && responseJson.data && responseJson.data.attributes) {
          this.setState({
            pipelineData: responseJson.data,
            stages: responseJson.data.attributes.stages
          });
        } else {
          this.setState({ pipelineData: { id: '', type: '', attributes: { description: '', permission_level: '', pipeline_name: '', stages: [], status: '' } }, });
        }
      }
    }
  }

  getAllTriggersApiCall = () => {
    let tokenData = "";
    if (typeof localStorage !== 'undefined') {
      tokenData = localStorage.getItem("token") || "";
    }
    const PCheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokenData
    };
    const getTriggersData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.triggersDataApiCallId = getTriggersData.messageId;

    getTriggersData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.triggersApiEndPoint);

    getTriggersData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getTriggersData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getTriggersData.id, getTriggersData);
  }

  getAllTriggersData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.triggersDataApiCallId) {
        if (responseJson && responseJson.errors) {
          this.setState({ triggersData: [] });
        } else if (Array.isArray(responseJson)) {
          this.setState({ triggersData: responseJson });
        } else {
          this.setState({ triggersData: [] });
        }
      }
    }
  }

  getAllEventsApiCall = () => {
    let eventTokenData = "";
    if (typeof localStorage !== 'undefined') {
      eventTokenData = localStorage.getItem("token") || "";
    }
    const Eventheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token: eventTokenData
    };
    const getEventsData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.eventsDataApiCallId = getEventsData.messageId;

    getEventsData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventsApiEndPoint);

    getEventsData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Eventheaders));

    getEventsData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getEventsData.id, getEventsData);
  }

  getAllEventsData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.eventsDataApiCallId) {
        if (responseJson && responseJson.errors) {
          this.setState({ eventsData: [] });
        } else if (Array.isArray(responseJson)) {
          this.setState({ eventsData: responseJson });
        } else {
          this.setState({ eventsData: [] });
        }
      }
    }
  }

  handleCreateRule = (event: any) => {
    event.preventDefault()
    let createRuleToken = "";
    if (typeof localStorage !== 'undefined') {
      createRuleToken = localStorage.getItem("token") || "";
    }
    const RuleBuilderAddPCheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token: createRuleToken
    };
    const { rule_name, pipeline_id, selectedTriggers, selectedEvents, selectedStages, triggerType, delay1, delay2, after } = this.state
    const errorMessages = [];

    if (!rule_name) errorMessages.push("Rule name is required.");
    if (!pipeline_id) errorMessages.push("Pipeline ID is required.");
    if (selectedTriggers.length === 0) errorMessages.push("At least one trigger must be selected.");
    if (selectedEvents.length === 0) errorMessages.push("At least one event must be selected.");
    if (selectedStages.length === 0) errorMessages.push("At least one stage must be selected.");
    if (!triggerType) errorMessages.push("And part of When is required.");
    if (!delay1) errorMessages.push("And 1 of After is required.");
    if (!delay2) errorMessages.push("And 2 of After is required.");
    if (!after) errorMessages.push("After date is required.");

    if (errorMessages.length > 0) {
      toast.error(errorMessages.join(' '), { style: { fontSize: '1.6rem' } });
      return;
    }
    const event_ids = selectedEvents.map(event => event.id);
    const trigger_ids = selectedTriggers.map(trigger => trigger.id)
    const stage_ids = selectedStages.map(stage => stage.id);
    const afterDateString = after ? after.format('DD/MM/YYYY') : null;
    const ruleBuilderBody = {
      rule: {
        rule_title: rule_name,
        pipeline_id: Number(pipeline_id),
        event_ids,
        trigger_ids,
        stage_ids,
        value1: triggerType,
        value2: delay1,
        value3: delay2,
        after: afterDateString
      }
    }
    const getRuleCreatedData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createRuleAPiCallId = getRuleCreatedData.messageId;

    getRuleCreatedData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ruleBuilderApiEndPoint);

    getRuleCreatedData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(RuleBuilderAddPCheaders));

    getRuleCreatedData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(ruleBuilderBody)
    );

    getRuleCreatedData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(getRuleCreatedData.id, getRuleCreatedData);
  }

  getRuleBuilderCreatedData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.createRuleAPiCallId) {
        if (responseJson.pipeline_id) {
          toast.success("Rule created successfully", { style: { fontSize: '1.6rem' } })
          this.setState({ rule_name: '', pipeline_name: '', selectedStages: [], selectedTriggers: [], triggerType: '', after: null, delay1: '', delay2: '', selectedEvents: [] })
          this.props.navigation.navigate('Cfstages1', { pipelineId: responseJson.pipeline_id })
        } else {
          toast.error(responseJson)
        }
      }
    }
  }

  handleChange = (event: any) => {
    const { value, name } = event.target;
    this.setState(prevState => { return { ...prevState, [name]: value } })
  };

  handleAutoComplete = (name: string) => (event: any, newValue: any) => {
    this.setState((prev) => {
      return {
        ...prev,
        [name]: newValue
      }
    });
  }

  handleDayChange = (newValue: any) => {
    this.setState({ after: newValue })
  }
  handleTriggerToggle = () => {
    this.setState((prevState) => ({ addTriggerModal: !prevState.addTriggerModal }))
  }

  handleEventToggle = () => {
    this.setState((prevState) => ({ addEventModal: !prevState.addEventModal }))
  }

  handleEditTriggerToggle = (data: any = "ii") => {
    const filteredTrigger = this.state.triggersData.find(trigger => trigger.id == data.id)
    this.setState((prevState) => ({
      ...prevState,
      editTriggerModal: !prevState.editTriggerModal,
      editTriggerPrevValue: filteredTrigger ?? prevState.editTriggerPrevValue,
      editTriggerName: filteredTrigger?.trigger_name ?? prevState.editTriggerName,
      editTriggerSelect1: filteredTrigger?.selection1 ?? prevState.editTriggerSelect1,
      editTriggerInput1: filteredTrigger?.value1 ?? prevState.editTriggerInput1,
      editTriggerSelect2: filteredTrigger?.selection2 ?? prevState.editTriggerSelect2,
      editTriggerInput2: filteredTrigger?.value2 ?? prevState.editTriggerInput2

    }));
  }

  handleEditEventToggle = (data: any = "i") => {
    const filteredEvent = this.state.eventsData.find(event => event.id == data.id)
    this.setState((prevState) => ({
      ...prevState,
      editEventModal: !prevState.editEventModal,
      editEventPrevValue: filteredEvent ?? prevState.editEventPrevValue,
      editEventName: filteredEvent?.event_name ?? prevState.editEventName,
      editEventSelect: filteredEvent?.dropdown ?? prevState.editEventSelect,
      editEventInput: filteredEvent?.value ?? prevState.editEventInput
    }));
  }

  handleAddEvent = (event: any) => {
    event.preventDefault()
    let addEventToken = "";
    if (typeof localStorage !== 'undefined') {
      addEventToken = localStorage.getItem("token") || "";
    }
    const EventAddPCheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token: addEventToken
    };
    const { eventName, eventSelect, eventInput } = this.state
    const addEventerrorMessages = [];

    if (!eventName) addEventerrorMessages.push("Event name is required.");
    if (!eventSelect) addEventerrorMessages.push("Event select type is required.");
    if (!eventInput) addEventerrorMessages.push("Event Input type is required.");

    if (addEventerrorMessages.length > 0) {
      toast.error(addEventerrorMessages.join(' '), { style: { fontSize: '1.6rem' } });
      return;
    }
    const eventBody = {
      event: {
        event_name: eventName,
        dropdown: eventSelect,
        value: eventInput
      }
    }
    const getEventCreatedData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createEventApiId = getEventCreatedData.messageId;

    getEventCreatedData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventsApiEndPoint);

    getEventCreatedData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(EventAddPCheaders));

    getEventCreatedData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(eventBody)
    );

    getEventCreatedData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(getEventCreatedData.id, getEventCreatedData);
  }

  getEventCreatedData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.createEventApiId) {
        if (responseJson.error) {
          toast.error(responseJson.error)
          this.handleEventToggle()
        } else if (responseJson.event_name) {
          toast.success(`Event added succesfully`, { style: { fontSize: '1.6rem' } })
          this.setState({ eventName: '', eventInput: '', eventSelect: '' })
          this.getAllEventsApiCall()
          this.handleEventToggle()
        }
      }
    }
  }

  handleAddTrigger = (event: any) => {
    event.preventDefault()
    let addTriggerToken = "";
    if (typeof localStorage !== 'undefined') {
      addTriggerToken = localStorage.getItem("token") || "";
    }
    const TriggerAddPCheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token: addTriggerToken
    };
    const { triggerName, triggerSelect1, triggerInput1, triggerSelect2, triggerInput2 } = this.state
    const addTriggererrorMessages = [];

    if (!triggerName) addTriggererrorMessages.push("Trigger name is required.");
    if (!triggerSelect1) addTriggererrorMessages.push("Trigger select1 type is required.");
    if (!triggerInput1) addTriggererrorMessages.push("Trigger Input1 type is required.");
    if (!triggerSelect2) addTriggererrorMessages.push("Trigger select2 type is required.");
    if (!triggerInput2) addTriggererrorMessages.push("Trigger Input2 type is required.");

    if (addTriggererrorMessages.length > 0) {
      toast.error(addTriggererrorMessages.join(' '), { style: { fontSize: '1.6rem' } });
      return;
    }
    const TriggerBody = {
      trigger: {
        trigger_name: triggerName,
        selection1: triggerSelect1,
        value1: triggerInput1,
        selection2: triggerSelect2,
        value2: triggerInput2
      }
    }
    const getTriggerCreatedData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createTriggerApiId = getTriggerCreatedData.messageId;

    getTriggerCreatedData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.triggersApiEndPoint);

    getTriggerCreatedData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(TriggerAddPCheaders));

    getTriggerCreatedData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(TriggerBody)
    );

    getTriggerCreatedData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(getTriggerCreatedData.id, getTriggerCreatedData);
  }

  getTriggerCreatedData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.createTriggerApiId) {
        if (responseJson.error) {
          toast.error(responseJson.error)
          this.handleTriggerToggle()
        } else if (responseJson.trigger_name) {
          toast.success(`Trigger added succesfully`, { style: { fontSize: '1.6rem' } })
          this.setState({ triggerName: '', triggerSelect1: '', triggerInput1: '', triggerSelect2: '', triggerInput2: '' })
          this.getAllTriggersApiCall()
          this.handleTriggerToggle()
        }
      }
    }
  }

  handleEditTriggerSave = (e: any) => {
    e.preventDefault()
    let editTriggerToken = "";
    if (typeof localStorage !== 'undefined') {
      editTriggerToken = localStorage.getItem("token") || "";
    }
    const TriggerEditHeader = {
      "Content-Type": configJSON.validationApiContentType,
      token: editTriggerToken
    };
    const { editTriggerName, editTriggerSelect1, editTriggerInput1, editTriggerSelect2, editTriggerInput2 } = this.state
    const httpBody = {
      trigger: {
        trigger_name: editTriggerName,
        selection1: editTriggerSelect1,
        value1: editTriggerInput1,
        selection2: editTriggerSelect2,
        value2: editTriggerInput2
      }
    }
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editTriggerApiId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.triggersApiEndPoint}/${this.state.editTriggerPrevValue.id}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(TriggerEditHeader));

    getAllData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethodType
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  getEditedTriggerDataValue(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.editTriggerApiId) {
        if (responseJson) {
          toast.success("Trigger edited successfully", { style: { fontSize: '1.6rem' } })
          this.getAllTriggersApiCall()
          this.handleEditTriggerToggle()
        } else {
          toast.error(responseJson)
          this.handleEditTriggerToggle()
        }
      }
    }
  }

  handleEditEventSave = (e: any) => {
    e.preventDefault()
    let editEventToken = "";
    if (typeof localStorage !== 'undefined') {
      editEventToken = localStorage.getItem("token") || "";
    }
    const EventEditHeader = {
      "Content-Type": configJSON.validationApiContentType,
      token: editEventToken
    };
    const { editEventName, editEventSelect, editEventInput } = this.state
    const eventBody = {
      event: {
        event_name: editEventName,
        dropdown: editEventSelect,
        value: editEventInput
      }
    }
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editEventApiId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventsApiEndPoint}/${this.state.editEventPrevValue.id}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(EventEditHeader));

    getAllData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(eventBody)
    );

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethodType
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  getEditedEventDataValue(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.editEventApiId) {
        if (responseJson) {
          toast.success("Event edited successfully", { style: { fontSize: '1.6rem' } })
          this.getAllEventsApiCall()
          this.handleEditEventToggle()
        } else {
          toast.error(responseJson)
          this.handleEditEventToggle()
        }
      }
    }
  }
  handleChangeModalValue = (event: any) => {
    const { value, name } = event.target;

    if (name === "triggerName") {
      this.setState(prevState => ({
        ...prevState,
        [name]: value,
        triggerSelect1: '',
        triggerInput1: '',
        triggerSelect2: '',
        triggerInput2: '',
        isTriggerSelect1Enabled: !!value,
        isTriggerInput1Enabled: false,
        isTriggerSelect2Enabled: false,
        isTriggerInput2Enabled: false
      }))
    } else if (name === "triggerSelect1") {
      this.setState(prevState => ({
        ...prevState,
        [name]: value,
        triggerInput1: '',
        triggerSelect2: '',
        triggerInput2: '',
        isTriggerInput1Enabled: !!value,
        isTriggerInput2Enabled: false,
        isTriggerSelect2Enabled: false
      }));
    } else if (name === "triggerInput1") {
      this.setState(prevState => ({
        ...prevState,
        [name]: value,
        triggerSelect2: '',
        triggerInput2: '',
        isTriggerSelect2Enabled: !!value,
        isTriggerInput2Enabled: false
      }));
    } else if (name === "triggerSelect2") {
      this.setState(prevState => ({
        ...prevState,
        [name]: value,
        isTriggerInput2Enabled: !!value,
      }));
    } else if (name == "eventName") {
      this.setState(prevState => ({
        ...prevState,
        [name]: value,
        eventSelect: '',
        eventInput: '',
        isEventSelectEnabled: !!value,
        isEventInputEnabled: false
      }));
    } else if (name == "eventSelect") {
      this.setState(prevState => ({
        ...prevState,
        [name]: value,
        eventInput: '',
        isEventInputEnabled: !!value
      }));
    }
    else {
      this.setState(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  }

  // Customizable Area End
}
