import React from "react";
// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import { styled } from "@mui/styles";
import { Box, TextField, MenuItem, Typography, Divider, Radio, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// Customizable Area End

import CreatePipelineController, {
    Props,
} from "./CreatePipelineController";

export default class CreatePipeline extends CreatePipelineController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderConditionalVisibleBY = (title: string, id: string, options: any, value: any) => {
        const icon = <CheckBoxOutlineBlankIcon style={{ height: "2rem", width: "2rem" }} />;
        const checkedIcon = <CheckBoxIcon style={{ height: "2rem", width: "2rem", color: "#1A4A4299" }} />;
        return <Box>
            <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                {title}
            </Typography>
            <Autocomplete
                multiple
                id={id}
                options={options}
                onChange={this.handleAutoComplete(id)}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                value={value}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                        <li {...optionProps} style={{ fontSize: '1.6rem', backgroundColor: selected ? '#1A4A421A' : 'transparent', fontWeight: 700, color: selected ? "#1A4A42" : "#0F172A" }}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )
                }}
                style={{ width: '100%' }}
                renderInput={(params) => (
                    <TextField
                        style={{ marginBottom: "0.8rem" }}
                        {...params}
                        placeholder={
                            Array.isArray(params.InputProps.startAdornment) && params.InputProps.startAdornment.length > 0
                                ? ''
                                : 'select'
                        }
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                fontWeight: 400,
                                paddingLeft: '0.3rem !important',
                                fontSize: "1.6rem",
                                '.css-18bmrv6-MuiButtonBase-root-MuiChip-root': {
                                    fontSize: "1.6rem",
                                    padding: '0',
                                }
                            },
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: 0,
                            },
                        }}
                    />
                )}
            />
        </Box>
    }

    renderVisibleBySection = (visibleByType: string) => {
        switch (visibleByType) {
            case 'Task Groups': return this.renderConditionalVisibleBY('Task Groups', 'selectedTasks', this.state.tasks, this.state.selectedTasks)
            case 'Individual Users': return this.renderConditionalVisibleBY('Individual Users', 'selectedUsers', this.state.users, this.state.selectedUsers)
            case 'Permission Levels': return this.renderConditionalVisibleBY('Permission Levels', 'selectedLevels', this.state.levels, this.state.selectedLevels)
        }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const visibleData = [{ label: "Task Groups", value: "Task Groups" }, { label: "Individual Users", value: "Individual Users" }, { label: "Permission Levels", value: "Permission Levels" }]
        const clinicData = [{ label: "Clinic 1", value: "Clinic 1" }, { label: "Clinic 2", value: "Clinic 2" }, { label: "Clinic 3", value: "Clinic 3" }, { label: "Clinic 4", value: "Clinic 4" }, { label: "Global", value: "Global" }]
        return (

            <CreatePipelineContainer>
                <TopNav navigation={this.props.navigation} id="pipelines" />
                <Divider sx={dividerStyle} />
                <Title>Create New Pipeline</Title>
                <Box component="form" noValidate autoComplete="off" onSubmit={this.handleCreatePipeline}>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Pipeline Name
                    </Typography>
                    <TextField
                        name="pipeline_name"
                        data-test-id="pipeline_name"
                        value={this.state.pipeline_name}
                        onChange={this.handleChangeValue}
                        fullWidth
                        InputLabelProps={{ shrink: false }}
                        placeholder="Type pipeline name"
                        style={{ marginBottom: "0.8rem" }}
                        inputProps={{
                            sx: {
                                padding: '1rem 0.8rem',
                                fontWeight: 400,
                                fontSize: '1.6rem'
                            },
                        }}
                    />
                    {this.state.errors.pipeline_name && (
                        <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{this.state.errors.pipeline_name}</Typography>
                    )}

                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Description
                    </Typography>
                    <TextField
                        name="description"
                        data-test-id="pipeline_desc"
                        value={this.state.description}
                        onChange={this.handleChangeValue}
                        fullWidth
                        InputLabelProps={{ shrink: false }}
                        placeholder="Type pipeline description"
                        style={{ marginBottom: "0.8rem" }}
                        inputProps={{
                            sx: {
                                padding: '1rem 0.8rem 4rem 0.8rem',
                                fontWeight: 400,
                                fontSize: '1.6rem'
                            },
                        }}
                    />
                    {this.state.errors.description && (
                        <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{this.state.errors.description}</Typography>
                    )}

                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Visible By
                    </Typography>
                    <TextField
                        name="visibleBy"
                        data-test-id="visibleBy"
                        value={this.state.visibleBy || ''}
                        onChange={this.handleChangeValue}
                        select
                        sx={{
                            marginBottom: "0.8rem"
                        }}
                        fullWidth
                        inputProps={{
                            sx: {
                                padding: '1rem 0.8rem',
                                fontWeight: 400,
                                fontSize: "1.6rem",
                                display: 'flex',
                                alignItems: 'center',
                            },
                        }}
                        InputLabelProps={{ shrink: false }}
                        SelectProps={{
                            displayEmpty: true,
                            renderValue: (selected: unknown) => {
                                if (!selected) {
                                    return <span style={{ color: "#999" }}>select</span>;
                                }
                                return selected as string;
                            },
                        }}
                    >
                        {visibleData.map((item, index) => (
                            <MenuItem key={index} value={item.value} sx={{
                                fontSize: '1.6rem', fontWeight: 400, backgroundColor: this.state.visibleBy === item.value ? '#1A4A421A' : 'white',
                            }}>
                                <FormControlLabel
                                    value={item}
                                    control={
                                        <Radio
                                            checked={this.state.visibleBy === item.value}
                                            onChange={this.handleChangeValue}
                                            value={item.value}
                                            name="visibleBy"
                                            sx={{
                                                padding: '0 0.8rem',
                                                '&.Mui-checked': {
                                                    color: '#1A4A42',
                                                },
                                                '&.MuiRadio-root': {
                                                    color: '#1A4A42',
                                                },
                                                '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                                                    backgroundColor: '#1A4A421A',
                                                },
                                            }}
                                        />
                                    }
                                    label={<Typography sx={{ fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>
                                        {item.label}
                                    </Typography>}
                                    sx={{
                                        fontSize: '1.6rem',
                                        fontWeight: '400'
                                    }}
                                />
                            </MenuItem>
                        ))}
                    </TextField>
                    {this.renderVisibleBySection(this.state.visibleBy)}
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Associated Clinic
                    </Typography>
                    <TextField
                        name="associatedClinic"
                        data-test-id="associatedClinic"
                        value={this.state.associatedClinic || ''}
                        onChange={this.handleChangeValue}
                        select
                        fullWidth
                        inputProps={{
                            sx: {
                                padding: '1rem 0.8rem',
                                fontWeight: 400,
                                fontSize: "1.6rem",
                                display: 'flex',
                                alignItems: 'center'
                            },
                        }}
                        InputLabelProps={{ shrink: false }}
                        SelectProps={{
                            displayEmpty: true,
                            renderValue: (selected: unknown) => {
                                if (!selected) {
                                    return <span style={{ color: "#999" }}>select</span>;
                                }
                                return selected as string;
                            },
                        }}
                    >
                        {clinicData.map((item, index) => (
                            <MenuItem key={index} value={item.value} sx={{
                                fontSize: '1.6rem', fontWeight: 400, backgroundColor: this.state.associatedClinic === item.value ? '#1A4A421A' : 'white',
                            }}>
                                <FormControlLabel
                                    value={item}
                                    control={
                                        <Radio
                                            checked={this.state.associatedClinic === item.value}
                                            onChange={this.handleChangeValue}
                                            value={item.value}
                                            name="associatedClinic"
                                            sx={{
                                                padding: '0 0.8rem',
                                                '&.Mui-checked': {
                                                    color: '#1A4A42',
                                                },
                                                '&.MuiRadio-root': {
                                                    color: '#1A4A42',
                                                },
                                                '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                                                    backgroundColor: '#1A4A421A',
                                                },
                                            }}
                                        />
                                    }
                                    label={<Typography sx={{ fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>
                                        {item.label}
                                    </Typography>}
                                    sx={{
                                        fontSize: '1.6rem',
                                        fontWeight: '400'
                                    }}
                                />
                            </MenuItem>
                        ))}
                    </TextField>
                    <WarningBox>
                        <StartButton type="submit" disabled={!this.isFormComplete()} style={{
                            backgroundColor: this.isFormComplete() ? "#1A4A42" : "#A8A29E66",
                            color: this.isFormComplete() ? "#fff" : "#78716C",
                            cursor: this.isFormComplete() ? "pointer" : "not-allowed",
                        }}>Start Pipeline</StartButton>
                        <WarningText>*You are creating a pipeline</WarningText>
                    </WarningBox>
                </Box>
            </CreatePipelineContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const CreatePipelineContainer = styled("div")({
    padding: "2rem 2rem 1rem",
    borderRadius: "1.2rem",
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
    boxSizing: "border-box",
    "@media (max-width: 600px)": {
        padding: "1.5rem",
    },
    "@media (max-width: 400px)": {
        padding: "1rem",
    },
});

const dividerStyle = {
    margin: "1.5rem 0",
    backgroundColor: "#1A4A4233",
};

const Title = styled("h1")({
    fontSize: "2rem",
    fontWeight: 600,
    margin: "2rem 0",
    color: "#292524",
});

const WarningBox = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    border: "0.1rem dashed #292524",
    padding: "1.5rem",
    borderRadius: "1.2rem",
    backgroundColor: "#f9f9f9",
    marginTop: "2.5rem"
});

const StartButton = styled("button")({
    width: "100%",
    padding: "1rem",
    border: "none",
    borderRadius: "0.8rem",
    fontSize: "1.6rem",
    cursor: "pointer",
    fontWeight: 600,
});

const WarningText = styled("span")({
    alignSelf: "flex-end",
    fontSize: "1.4rem",
    color: "#737373",
    fontWeight: 400,
    fontStyle: "italic",
});
// Customizable Area End
