// App.js - WEB
import React, { Component, useEffect } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfgoals1 from "../../blocks/cfgoals1/src/Cfgoals1";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Cfstages1 from "../../blocks/cfstages1/src/Cfstages1";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import DesktopNotifications from "../../blocks/desktopnotifications/src/DesktopNotifications";
import Cfmicrosoftdefenderforcloud7 from "../../blocks/cfmicrosoftdefenderforcloud7/src/Cfmicrosoftdefenderforcloud7";
import Cfdealhistory1 from "../../blocks/cfdealhistory1/src/Cfdealhistory1";
import PasswordStrength from "../../blocks/passwordstrength/src/PasswordStrength";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Cfdealpropertiesevents1 from "../../blocks/cfdealpropertiesevents1/src/Cfdealpropertiesevents1";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Cfcallqueueing3 from "../../blocks/cfcallqueueing3/src/Cfcallqueueing3";
import Cfemaillistener3 from "../../blocks/cfemaillistener3/src/Cfemaillistener3";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Cfdealpropertieslistener1 from "../../blocks/cfdealpropertieslistener1/src/Cfdealpropertieslistener1";
import Cftelephonyevents3 from "../../blocks/cftelephonyevents3/src/Cftelephonyevents3";
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Cfcontactpropertieslistener3 from "../../blocks/cfcontactpropertieslistener3/src/Cfcontactpropertieslistener3";
import Cfdeals1 from "../../blocks/cfdeals1/src/Cfdeals1";
import Cftimedelays1 from "../../blocks/cftimedelays1/src/Cftimedelays1";
import Cfchatbotevents3 from "../../blocks/cfchatbotevents3/src/Cfchatbotevents3";
import Cfemailevents3 from "../../blocks/cfemailevents3/src/Cfemailevents3";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Cfsmsevents3 from "../../blocks/cfsmsevents3/src/Cfsmsevents3";
import Cfnonstandardinfrasetup3 from "../../blocks/cfnonstandardinfrasetup3/src/Cfnonstandardinfrasetup3";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Cfcontacthistory3 from "../../blocks/cfcontacthistory3/src/Cfcontacthistory3";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Sorting from "../../blocks/sorting/src/Sorting";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cfworkflows1 from "../../blocks/cfworkflows1/src/Cfworkflows1";
import Cfmergetags1 from "../../blocks/cfmergetags1/src/Cfmergetags1";
import Pagination from "../../blocks/pagination/src/Pagination";
import Cfconditionallogic3 from "../../blocks/cfconditionallogic3/src/Cfconditionallogic3";
import Cftelephonylistener3 from "../../blocks/cftelephonylistener3/src/Cftelephonylistener3";
import Cftasklistener3 from "../../blocks/cftasklistener3/src/Cftasklistener3";
import Cfchatbotlistener3 from "../../blocks/cfchatbotlistener3/src/Cfchatbotlistener3";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Cfcontactlists3 from "../../blocks/cfcontactlists3/src/Cfcontactlists3";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfautomateddataencryption3 from "../../blocks/cfautomateddataencryption3/src/Cfautomateddataencryption3";
import Cfcontactpropertiesevents3 from "../../blocks/cfcontactpropertiesevents3/src/Cfcontactpropertiesevents3";
import Cfsmslistener3 from "../../blocks/cfsmslistener3/src/Cfsmslistener3";
import Cfpipelines1 from "../../blocks/cfpipelines1/src/Cfpipelines1";
import DailyScheduleNotifications from "../../blocks/dailyschedulenotifications/src/DailyScheduleNotifications";
import Cftaskevents3 from "../../blocks/cftaskevents3/src/Cftaskevents3";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Cfutmtracking3 from "../../blocks/cfutmtracking3/src/Cfutmtracking3";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import SideNav from "../../blocks/navigationmenu/src/SideNav.web"
import CreatePipeline from "../../blocks/cfpipelines1/src/CreatePipeline.web"
import PipelineDetail from "../../blocks/cfpipelines1/src/PipelineDetail.web"
import CreatePipelineRule from "../../blocks/cfpipelines1/src/CreatePipelineRule.web"
import Cfstage1Details from "../../blocks/cfstages1/src/Cfstage1Details.web"
import EditStageDetails from "../../blocks/cfstages1/src/EditStageDetails.web"
import CfDealDetails from "../../blocks/cfstages1/src/CfDealDetails.web"
import EditCfDealDetails from "../../blocks/cfstages1/src/EditCfDealDetails.web"
import CreateCfDeal from "../../blocks/cfstages1/src/CreateCfDeal.web"
import Createtaskgroup from "../../blocks/cftasklistener3/src/Createtaskgroup.web"
import Detailtaskgroup from "../../blocks/cftasklistener3/src/Detailtaskgroup.web"
import Edittaskgroup from "../../blocks/cftasklistener3/src/Edittaskgroup.web"
import { createTheme, ThemeProvider } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
const routeMap = {
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Cfgoals1: {
    component: Cfgoals1,
    path: "/Cfgoals1"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  Cfstages1: {
    component: Cfstages1,
    path: "/Cfpipelines1/Cfstages1"
  },
  Cfstage1details: {
    component: Cfstage1Details,
    path: '/Cfpipelines1/Cfstage1Details'
  },
  EditStageDetails: {
    component: EditStageDetails,
    path: '/Cfpipelines1/EditStageDetails'
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  DesktopNotifications: {
    component: DesktopNotifications,
    path: "/DesktopNotifications"
  },
  Cfmicrosoftdefenderforcloud7: {
    component: Cfmicrosoftdefenderforcloud7,
    path: "/Cfmicrosoftdefenderforcloud7"
  },
  Cfdealhistory1: {
    component: Cfdealhistory1,
    path: "/Cfdealhistory1"
  },
  PasswordStrength: {
    component: PasswordStrength,
    path: "/PasswordStrength"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  Cfdealpropertiesevents1: {
    component: Cfdealpropertiesevents1,
    path: "/Cfdealpropertiesevents1"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  Cfcallqueueing3: {
    component: Cfcallqueueing3,
    path: "/Cfcallqueueing3"
  },
  Cfemaillistener3: {
    component: Cfemaillistener3,
    path: "/Cfemaillistener3"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  Cfdealpropertieslistener1: {
    component: Cfdealpropertieslistener1,
    path: "/Cfdealpropertieslistener1"
  },
  Cftelephonyevents3: {
    component: Cftelephonyevents3,
    path: "/Cftelephonyevents3"
  },
  DragDropInterface: {
    component: DragDropInterface,
    path: "/DragDropInterface"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  Cfcontactpropertieslistener3: {
    component: Cfcontactpropertieslistener3,
    path: "/Cfcontactpropertieslistener3"
  },
  Cfdeals1: {
    component: Cfdeals1,
    path: "/Cfdeals1"
  },
  Cftimedelays1: {
    component: Cftimedelays1,
    path: "/Cftimedelays1"
  },
  Cfchatbotevents3: {
    component: Cfchatbotevents3,
    path: "/Cfchatbotevents3"
  },
  Cfemailevents3: {
    component: Cfemailevents3,
    path: "/Cfemailevents3"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  Cfsmsevents3: {
    component: Cfsmsevents3,
    path: "/Cfsmsevents3"
  },
  Cfnonstandardinfrasetup3: {
    component: Cfnonstandardinfrasetup3,
    path: "/Cfnonstandardinfrasetup3"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  Cfcontacthistory3: {
    component: Cfcontacthistory3,
    path: "/Cfcontacthistory3"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Appointments: {
    component: Appointments,
    path: "/Appointments"
  },
  AddAppointment: {
    component: AddAppointment,
    path: "/AddAppointment"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Cfworkflows1: {
    component: Cfworkflows1,
    path: "/Cfworkflows1"
  },
  Cfmergetags1: {
    component: Cfmergetags1,
    path: "/Cfmergetags1"
  },
  Pagination: {
    component: Pagination,
    path: "/Pagination"
  },
  Cfconditionallogic3: {
    component: Cfconditionallogic3,
    path: "/Cfconditionallogic3"
  },
  Cftelephonylistener3: {
    component: Cftelephonylistener3,
    path: "/Cftelephonylistener3"
  },
  Cftasklistener3: {
    component: Cftasklistener3,
    path: "/Taskgroups"
  },
  Createtaskgroup: {
    component: Createtaskgroup,
    path: '/Taskgroups/Createtaskgroup'
  },
  Detailtaskgroup: {
    component: Detailtaskgroup,
    path: '/Taskgroups/Detailtaskgroup'
  },
  Edittaskgroup: {
    component: Edittaskgroup,
    path: '/Taskgroups/Edittaskgroup'
  },
  Cfchatbotlistener3: {
    component: Cfchatbotlistener3,
    path: "/Cfchatbotlistener3"
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: "/Servicespecificsettingsadmin2"
  },
  Cfcontactlists3: {
    component: Cfcontactlists3,
    path: "/Cfcontactlists3"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Cfautomateddataencryption3: {
    component: Cfautomateddataencryption3,
    path: "/Cfautomateddataencryption3"
  },
  Cfcontactpropertiesevents3: {
    component: Cfcontactpropertiesevents3,
    path: "/Cfcontactpropertiesevents3"
  },
  Cfsmslistener3: {
    component: Cfsmslistener3,
    path: "/Cfsmslistener3"
  },
  Cfpipelines1: {
    component: Cfpipelines1,
    path: "/Cfpipelines1"
  },
  DailyScheduleNotifications: {
    component: DailyScheduleNotifications,
    path: "/DailyScheduleNotifications"
  },
  Cftaskevents3: {
    component: Cftaskevents3,
    path: "/Cftaskevents3"
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: "/Notificationsettings"
  },
  Cfutmtracking3: {
    component: Cfutmtracking3,
    path: "/Cfutmtracking3"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  CreatePipeline: {
    component: CreatePipeline,
    path: '/Cfpipelines1/CreatePipeline'
  },
  PipelineDetail: {
    component: PipelineDetail,
    path: '/Cfpipelines1/PipelineDetail'
  },
  CreatePipelineRule: {
    component: CreatePipelineRule,
    path: '/Cfpipelines1/CreatePipelineRule'
  },
  CfDealDetails: {
    component: CfDealDetails,
    path: '/Cfpipelines1/Cfstage1Details/CfDealDetails'
  },
  EditCfDealDetails: {
    component: EditCfDealDetails,
    path: '/Cfpipelines1/Cfstage1Details/EditCfDealDetails'
  },
  CreateCfDeal: {
    component: CreateCfDeal,
    path: '/Cfpipelines1/Cfstage1Details/CreateCfDeal'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const tokenData = localStorage.getItem("token")
  useEffect(() => {
    if (!tokenData) {
      if (location.pathname !== '/EmailAccountLoginBlock') {
        navigate('EmailAccountLoginBlock');
      }
    } else {
      if (location.pathname === '/EmailAccountLoginBlock') {
        navigate('/');
      }
    }
  }, [tokenData, location.pathname, navigate]);

  const theme = createTheme({
    typography: {
      fontFamily: 'Raleway, Arial, sans-serif',
    },
  });
  return (
    <BuilderProvider>
      <ThemeProvider theme={theme}>
        <View style={{ height: '100vh', width: '100%' }}>
          <div style={{ display: "flex", padding: location.pathname === "/EmailAccountLoginBlock" ? '0px' : '10px', gap: "10px", maxWidth: "100%" }}>
            <div style={{ display: location.pathname === "/EmailAccountLoginBlock" ? 'none' : 'flex', flex: 1, justifyContent: 'center' }}>
              <SideNav location={location} navigate={navigate} />
            </div>
            <div style={{ flex: 5 }}>
              <WebRoutesGenerator routeMap={routeMap} />
            </div>
          </div>
          <Outlet />
          <AlertBlock />
          <ToastContainer />
        </View>
      </ThemeProvider>
    </BuilderProvider>
  );
}

export default App;