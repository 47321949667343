import React from "react";
// Customizable Area Start
import CfCreateEditDeal from "./CfCreateEditDeal.web"
// Customizable Area End

import CreateCfDealController, {
    Props,
} from "./CreateCfDealController";

export default class CreateCfDeal extends CreateCfDealController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { description, asso_pipeline, selectedPriority, users, stageId, deal_name, due_date, assigned_to } = this.state
        return (
            <CfCreateEditDeal
                navigation={this.props.navigation}
                id="createDeal"
                title="Create New Deal"
                asso_pipeline={asso_pipeline}
                users={users}
                selectedPriority={selectedPriority}
                handleChange={this.handleChangeCreateDeal}
                handleDayChange={this.handleCreateDealDayChange}
                handlePriorityChange={this.handleCreateDealPriorityChange}
                description={description}
                handleEditStage={this.handleCreateDeal}
                deal_name={deal_name}
                due_date={due_date}
                assigned_to={assigned_to}
                stageId={stageId}
                isEditStageFormComplete={this.isCreateDealFormComplete}
                handleAssignedChange={this.handleCreateAssignedChanged}
                buttonText="Create Deal"
            />
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
