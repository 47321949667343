import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/material';
import React from "react";

interface PaginationProps {
    currentPageIndex: number,
    requiredPageSize: number
    startIndex: number
    endIndex: number
    totalResults: number, 
    handlePageChange: (e:any, v:number) => void
}
const PaginationComponent = ({currentPageIndex, requiredPageSize, startIndex, endIndex, totalResults, handlePageChange}: PaginationProps) => {
    return (
        <Box style={{ display: 'flex', alignItems: "center", marginTop: '0.8rem', paddingTop: "0.5rem", justifyContent: 'space-between', fontFamily: "Outfit" }}>
            <Box style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <Pagination
                    count={Math.ceil(totalResults / requiredPageSize)}
                    page={currentPageIndex + 1}
                    onChange={handlePageChange}
                    color="standard"
                    sx={{
                        '& .css-7s3al-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '0.8rem',
                            color: '#1A4A42',
                            fontWeight: 700
                        },
                        '& .css-7s3al-MuiButtonBase-root-MuiPaginationItem-root': {
                            fontSize: '1.4rem',
                            fontFamily: 'Outfit',
                            fontWeight: 400,
                            minWidth: '2.6rem',
                            height: '2.4rem',
                            color: '#1A4A424D'
                        },
                        '& .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon': {
                            width: '2em',
                            height: '2em',
                            fill: '#292524'
                        }
                    }}
                    style={{ marginLeft: '2rem', borderRadius: "0", fontSize: '1.6rem' }}
                />
            </Box>
            <span style={{ margin: 0, fontSize: "1.2rem", fontWeight: 300, fontFamily: 'Outfit' }}>{`${startIndex} - ${endIndex}`}<span style={{ fontFamily: "Raleway", fontWeight: 400 }}> of </span>{`${totalResults} results`}</span>
        </Box>
    )
}
export default PaginationComponent