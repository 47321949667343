import { v4 as uuidv4 } from 'uuid';
import {Dayjs} from "dayjs"
export interface PipelineType {
  id: string,
  pipeline_name: string,
  description: string,
  created_at: string,
  updated_at: string,
  permission_level: string,
  pipeline_serial_id: string,
  status: null | string,
  order: null,
  stages: StagesDataType[],
  clinic_name: string,
  visibleBy: VisibleBy
  rules: RulesType[]
}
interface VisibleBy {
  PermissionLevels: LevelType[]
  TaskGroups: TaskGroupType[]
  IndividualUsers: UserType[]
}
export interface StagesDataType {
  id: string,
  stage_name: string,
  description: string,
  task: null,
  permission_level: string,
  created_at: string,
  updated_at: string,
  pipeline_id: string,
  rule_builder_id: null | number
  deals: DealType[]
}
interface LevelType {
  level_name: string
}
interface TaskGroupType {
  task_name: string
}
export interface UserType {
  user_name: string,
  source: string
}
interface RulesType {
  rule_name: string
  rule_desc: string
}
export interface DealType {
  deal_name: string,
  deal_id: string,
  deal_desc: string,
  priority: string
  due_date: Dayjs | string
  created_at: string,
  stage_id: string
  pipeline_id: string
  asso_user: UserType
}
export const formatDateTimeTable = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('en-GB', { month: 'short' });
  const year = date.getFullYear();

  const ordinal = (n: any) => {
    const suffix = ["th", "st", "nd", "rd"];
    const value = n % 100;
    return n + (suffix[(value - 20) % 10] || suffix[value] || suffix[0]);
  };

  return `${ordinal(day)} ${month} ${year}`;
}
export const formatDateTimeField = (dateValue: any) => {
  const dateData = new Date(dateValue);

  const dayValue = dateData.getDate();
  const monthValue = dateData.toLocaleString('en-US', { month: 'long' });
  const yearValue = dateData.getFullYear();

  const paddedDay = dayValue < 10 ? `0${dayValue}` : dayValue;

  return `${monthValue} ${paddedDay}, ${yearValue}`;
};
const generateCustomPipelineId = (index: number) => {
  return `#P${(index + 1).toString().padStart(8, '0')}`;
};
const generateCustomStageId = (index: number) => {
  return `#S${(index + 1).toString().padStart(6, '0')}`;
};
const generateCustomDealId = (index: number) => {
  return `D${(index + 1).toString().padStart(8, '0')}`;
};
const americanNames = [
  "John Smith", "Michael Johnson", "James Brown", "Robert Williams", "David Jones",
  "Mary Miller", "Jennifer Davis", "Patricia Garcia", "Linda Martinez", "Barbara Wilson"
];
export const getRandomAmericanName = () => {
  const index = uuidv4().charCodeAt(0) % americanNames.length;
  return americanNames[index];
};

const fixedUsers: UserType[] = [
  { user_name: "John Smith", source: "https://randomuser.me/api/portraits/men/1.jpg" },
  { user_name: "Michael Johnson", source: "https://randomuser.me/api/portraits/men/2.jpg" },
  { user_name: "James Brown", source: "https://randomuser.me/api/portraits/men/3.jpg" },
  { user_name: "Robert Williams", source: "https://randomuser.me/api/portraits/men/4.jpg" },
  { user_name: "David Jones", source: "https://randomuser.me/api/portraits/men/5.jpg" },
  { user_name: "Mary Miller", source: "https://randomuser.me/api/portraits/women/1.jpg" },
  { user_name: "Jennifer Davis", source: "https://randomuser.me/api/portraits/women/2.jpg" },
  { user_name: "Patricia Garcia", source: "https://randomuser.me/api/portraits/women/3.jpg" },
  { user_name: "Linda Martinez", source: "https://randomuser.me/api/portraits/women/4.jpg" },
  { user_name: "Barbara Wilson", source: "https://randomuser.me/api/portraits/women/5.jpg" },
  { user_name: "William Taylor", source: "https://randomuser.me/api/portraits/men/6.jpg" },
  { user_name: "Elizabeth Anderson", source: "https://randomuser.me/api/portraits/women/6.jpg" },
  { user_name: "Richard Thomas", source: "https://randomuser.me/api/portraits/men/7.jpg" },
  { user_name: "Susan Jackson", source: "https://randomuser.me/api/portraits/women/7.jpg" },
  { user_name: "Charles White", source: "https://randomuser.me/api/portraits/men/8.jpg" },
  { user_name: "Jessica Harris", source: "https://randomuser.me/api/portraits/women/8.jpg" },
  { user_name: "Daniel Clark", source: "https://randomuser.me/api/portraits/men/9.jpg" },
  { user_name: "Emily Lewis", source: "https://randomuser.me/api/portraits/women/9.jpg" },
  { user_name: "Thomas Robinson", source: "https://randomuser.me/api/portraits/men/10.jpg" },
  { user_name: "Sophia King", source: "https://randomuser.me/api/portraits/women/10.jpg" },
  { user_name: "Joseph Walker", source: "https://randomuser.me/api/portraits/men/11.jpg" },
  { user_name: "Ava Hall", source: "https://randomuser.me/api/portraits/women/11.jpg" },
  { user_name: "Mason Allen", source: "https://randomuser.me/api/portraits/men/12.jpg" },
  { user_name: "Lily Young", source: "https://randomuser.me/api/portraits/women/12.jpg" },
  { user_name: "Ethan Wright", source: "https://randomuser.me/api/portraits/men/13.jpg" },
  { user_name: "Zoe Scott", source: "https://randomuser.me/api/portraits/women/13.jpg" },
  { user_name: "Lucas Adams", source: "https://randomuser.me/api/portraits/men/14.jpg" },
  { user_name: "Grace Baker", source: "https://randomuser.me/api/portraits/women/14.jpg" },
  { user_name: "Benjamin Carter", source: "https://randomuser.me/api/portraits/men/15.jpg" },
  { user_name: "Madison Mitchell", source: "https://randomuser.me/api/portraits/women/15.jpg" },
  { user_name: "Henry Perez", source: "https://randomuser.me/api/portraits/men/16.jpg" },
  { user_name: "Ella Roberts", source: "https://randomuser.me/api/portraits/women/16.jpg" },
  { user_name: "Jack Evans", source: "https://randomuser.me/api/portraits/men/17.jpg" },
  { user_name: "Chloe Collins", source: "https://randomuser.me/api/portraits/women/17.jpg" }
];

const getRandomInt = (min: number, max: number) => {
  const randomValue = uuidv4().charCodeAt(0);
  return min + (randomValue % (max - min + 1));
};
const generateRandomRules = () => {
  const ruleDescriptions = [
    "This rule contains all deals for users whose birthday is not today.",
    "This rule applies to users who have logged in within the past 30 days.",
    "This rule applies to users who have completed their profile setup.",
    "This rule is active for users in the 'Premium' membership tier.",
    "This rule targets users who have made a purchase in the last 6 months."
  ];

  const ruleCount = getRandomInt(1, 3);
  return Array.from({ length: ruleCount }, (_, index) => ({
    rule_name: `Rule ${index + 1}`,
    rule_desc: ruleDescriptions[index % ruleDescriptions.length]
  }));
};

export const generateRandomAvatar = () => {
  const gender = uuidv4().charCodeAt(0) % 2 === 0 ? 'men' : 'women';
  const avatarId = getRandomInt(0, 99);
  return `https://randomuser.me/api/portraits/${gender}/${avatarId}.jpg`;
};

const generateDescription = (pipelineName: string) => {
  const baseDescription = `This is a detailed description of ${pipelineName}. It includes information on stages, assigned levels, clinic affiliations, and specific tasks or objectives related to this pipeline.`;
  const additionalText = ` The ${pipelineName} is crucial for managing resources efficiently and ensuring that tasks are completed according to specified requirements. Its success is fundamental to achieving key objectives.`;

  return (baseDescription + additionalText).slice(0, 300);
};

export const generateRandomDate = () => {
  const randomDays = getRandomInt(1, 30);
  const dueDate = new Date();
  dueDate.setDate(dueDate.getDate() + randomDays);

  return dueDate.toISOString();
}

export const generateRandomDeals = (stageId: string, pipelineId: string) => {
  const dealCount = getRandomInt(6, 20);
  const priorities = ['High', 'Medium', 'Low'];
  return Array.from({ length: dealCount }, (_, index) => ({
    deal_name: `Deal ${index + 1}`,
    deal_id: generateCustomDealId(index),
    deal_desc: `This is the description for Deal ${index + 1}`,
    priority: priorities[getRandomInt(0, priorities.length - 1)],
    due_date: generateRandomDate(),
    created_at: generateRandomDate(),
    stage_id: stageId,
    pipeline_id: pipelineId,
    asso_user: {
      user_name: getRandomAmericanName(),
      source: generateRandomAvatar(),
    }
  }));
};

export const generateRandomUsers = (userCount: number) => {
  const limitedUserCount = Math.min(userCount, fixedUsers.length);
  return fixedUsers.slice(0, limitedUserCount);
};

export const generatePipeline = (count = 36) => {
  const levels = ['Level 1', 'Level 2', 'Level 3', 'Level 4'];
  const clinicNames = ['Clinic 1', 'Clinic 2', 'Clinic 3', 'Global'];
  const stagesNames = ['Stage 1', 'Stage 2', 'Stage 3', 'Stage 4', 'Stage 5', 'Stage 6', 'Stage 7'];
  const randomPipelines = [];

  const generateUniqueStageName = (existingStageNames: Set<string>, baseName: string): string => {
    let stageName = baseName;
    let count = 1;

    while (existingStageNames.has(stageName)) {
      stageName = `${baseName} ${count}`;
      count++;
    }

    existingStageNames.add(stageName);

    return stageName;
  };

  for (let i = 0; i < count; i++) {
    const randomId = generateCustomPipelineId(i);
    const pipelineName = `Pipeline ${i + 1}`;
    const description = generateDescription(pipelineName);
    const permissionLevel = levels[i % levels.length];
    const clinicName = clinicNames[i % clinicNames.length];

    const stages = [];
    const existingStageNames = new Set<string>();

    for (let j = 0; j < 7; j++) {
      const stageName = generateUniqueStageName(existingStageNames, stagesNames[j]);

      const stage = {
        id: generateCustomStageId(j),
        stage_name: stageName,
        description: `Description for ${stageName}`,
        task: null,
        permission_level: levels[j % levels.length],
        created_at: generateRandomDate(),
        updated_at: generateRandomDate(),
        pipeline_id: randomId,
        rule_builder_id: null,
        deals: generateRandomDeals(generateCustomStageId(j), randomId),
      };

      stages.push(stage);
    }

    const visibleBy = {
      PermissionLevels: levels.slice(0, getRandomInt(1, 4)).map(level => ({ level_name: level })),
      TaskGroups: Array.from({ length: getRandomInt(3, 6) }, (_, index) => ({
        task_name: `Task group ${index + 1}`,
      })),
      IndividualUsers: generateRandomUsers(6)
    };

    const pipeline = {
      id: randomId,
      pipeline_name: pipelineName,
      description: description,
      order: null,
      pipeline_serial_id: (i + 1).toString(),
      status: null,
      permission_level: permissionLevel,
      created_at: generateRandomDate(),
      updated_at: generateRandomDate(),
      stages: stages,
      clinic_name: clinicName,
      visibleBy: visibleBy,
      rules: generateRandomRules(),
    };

    randomPipelines.push(pipeline);
  }

  return randomPipelines;
};