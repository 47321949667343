import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
interface TaskAddRequiredErrorsType {
  task_name?: string,
  description?: string,
  userMembers?: string
}
import { TaskDataType, UserType } from "./utils/generateTasks";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openRows: any
  selectedTasks: { [key: number]: boolean }
  task_name: string
  description: string
  errors: TaskAddRequiredErrorsType
  selectedUsers: UserType[]
  taskId: number | null
  tasks: TaskDataType[]
  currentPageIndex: number
  requiredPageSize: number
  sortField: string
  sortOrder: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cftasklistener3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openRows: {},
      selectedTasks: {},
      task_name: '',
      description: '',
      errors: {},
      selectedUsers: [],
      taskId: null,
      tasks: [],
      currentPageIndex: 0,
      requiredPageSize: 10,
      sortField: 'id',
      sortOrder: 'asc',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  getNumericTaskId = (formattedId: any): number => {
    const match = formattedId.match(/\d+/);
    return match ? Number(match[0]) : NaN;
  };
  handleSortTasks = (field: string) => {
    const { sortField, sortOrder } = this.state;

    if (sortField === field) {
      this.setState({
        sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortField: field,
        sortOrder: 'asc',
      });
    }
  };
  onPageChangeTask = (page: number) => this.setState({ currentPageIndex: page })
  handlePreviousTask = () => {
    if (this.state.currentPageIndex > 0) this.onPageChangeTask(this.state.currentPageIndex - 1);
  };
  handlePageChangeTask = (event: any, value: number) => {
    this.setState({ currentPageIndex: value - 1 });
  };

  handleNextTask = (totalPages: number) => {
    if (this.state.currentPageIndex < totalPages - 1) this.onPageChangeTask(this.state.currentPageIndex + 1);
  };

  handleSortTaskData = () => {
    const { sortOrder, tasks, sortField } = this.state;
    if (!Array.isArray(tasks) || tasks.length === 0) {
      return [];
    }

    const sortedData = [...tasks].sort((a, b) => {

      let comparison = 0;

      if (sortField === 'id') {
        comparison = this.getNumericTaskId(a.task_id) - this.getNumericTaskId(b.task_id);
      } else if (sortField === 'name') {
        comparison = a.task_name.localeCompare(b.task_name);
      } else if (sortField === 'created_at' || sortField === 'updated_at') {
        const dateA = new Date(a[sortField]).getTime();
        const dateB = new Date(b[sortField]).getTime();
        comparison = dateA - dateB;
      }

      return sortOrder === 'asc' ? comparison : -comparison;
    });

    return sortedData
  };
  handleRowClick = (rowName: string) => {
    this.setState((prevState) => ({
      openRows: {
        ...prevState.openRows,
        [rowName]: !prevState.openRows[rowName],
      },
    }));
  };
  
  handleTaskCreate = () => {
    this.props.navigation.navigate("Createtaskgroup")
  }
  // Customizable Area End
}
