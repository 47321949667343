import { v4 as uuidv4 } from 'uuid';

export interface TaskDataType {
    task_id: string,
    task_name: string,
    created_at: string,
    updated_at: string,
    asso_pipelines: string[],
    individualUser: UserType[],
    levels: string[],
    task_desc: string,
}
export interface UserType { 
    user_name: string, 
    source: string 
  }
const generateCustomTaskId = (index: number) => {
    return `#TG${(index + 1).toString().padStart(8, '0')}`;
};

const generateTaskDescription = (taskName: string) => {
    const baseDescription = `This is a detailed description of ${taskName}. It includes information on associated pipelines, permission levels, and the task's objectives.`;
    const additionalText = ` The ${taskName} is essential for ensuring smooth operations and achieving the desired goals.`;
    return (baseDescription + additionalText).slice(0, 300);
};

export const generateRandomPipelines = (pipelineCount: number) => {
    return Array.from({ length: pipelineCount }, (_, index) => `#P${(index + 1).toString().padStart(8, '0')}`);
};

const fixedUsers: UserType[] = [
    { user_name: "John Smith", source: "https://randomuser.me/api/portraits/men/1.jpg" },
    { user_name: "Michael Johnson", source: "https://randomuser.me/api/portraits/men/2.jpg" },
    { user_name: "James Brown", source: "https://randomuser.me/api/portraits/men/3.jpg" },
    { user_name: "Robert Williams", source: "https://randomuser.me/api/portraits/men/4.jpg" },
    { user_name: "David Jones", source: "https://randomuser.me/api/portraits/men/5.jpg" },
    { user_name: "Mary Miller", source: "https://randomuser.me/api/portraits/women/1.jpg" },
    { user_name: "Jennifer Davis", source: "https://randomuser.me/api/portraits/women/2.jpg" },
    { user_name: "Patricia Garcia", source: "https://randomuser.me/api/portraits/women/3.jpg" },
    { user_name: "Linda Martinez", source: "https://randomuser.me/api/portraits/women/4.jpg" },
    { user_name: "Barbara Wilson", source: "https://randomuser.me/api/portraits/women/5.jpg" },
    { user_name: "William Taylor", source: "https://randomuser.me/api/portraits/men/6.jpg" },
    { user_name: "Elizabeth Anderson", source: "https://randomuser.me/api/portraits/women/6.jpg" },
    { user_name: "Richard Thomas", source: "https://randomuser.me/api/portraits/men/7.jpg" },
    { user_name: "Susan Jackson", source: "https://randomuser.me/api/portraits/women/7.jpg" },
    { user_name: "Charles White", source: "https://randomuser.me/api/portraits/men/8.jpg" },
    { user_name: "Jessica Harris", source: "https://randomuser.me/api/portraits/women/8.jpg" },
    { user_name: "Daniel Clark", source: "https://randomuser.me/api/portraits/men/9.jpg" },
    { user_name: "Emily Lewis", source: "https://randomuser.me/api/portraits/women/9.jpg" },
    { user_name: "Thomas Robinson", source: "https://randomuser.me/api/portraits/men/10.jpg" },
    { user_name: "Sophia King", source: "https://randomuser.me/api/portraits/women/10.jpg" },
    { user_name: "Joseph Walker", source: "https://randomuser.me/api/portraits/men/11.jpg" },
    { user_name: "Ava Hall", source: "https://randomuser.me/api/portraits/women/11.jpg" },
    { user_name: "Mason Allen", source: "https://randomuser.me/api/portraits/men/12.jpg" },
    { user_name: "Lily Young", source: "https://randomuser.me/api/portraits/women/12.jpg" },
    { user_name: "Ethan Wright", source: "https://randomuser.me/api/portraits/men/13.jpg" },
    { user_name: "Zoe Scott", source: "https://randomuser.me/api/portraits/women/13.jpg" },
    { user_name: "Lucas Adams", source: "https://randomuser.me/api/portraits/men/14.jpg" },
    { user_name: "Grace Baker", source: "https://randomuser.me/api/portraits/women/14.jpg" },
    { user_name: "Benjamin Carter", source: "https://randomuser.me/api/portraits/men/15.jpg" },
    { user_name: "Madison Mitchell", source: "https://randomuser.me/api/portraits/women/15.jpg" },
    { user_name: "Henry Perez", source: "https://randomuser.me/api/portraits/men/16.jpg" },
    { user_name: "Ella Roberts", source: "https://randomuser.me/api/portraits/women/16.jpg" },
    { user_name: "Jack Evans", source: "https://randomuser.me/api/portraits/men/17.jpg" },
    { user_name: "Chloe Collins", source: "https://randomuser.me/api/portraits/women/17.jpg" }
];

const getRandomInt = (min: number, max: number) => {
    const randomValue = uuidv4().charCodeAt(0);
    return min + (randomValue % (max - min + 1));
};

export const generateRandomUsers = (userCount: number) => {
    const shuffledUsers = [...fixedUsers];
    
    for (let i = shuffledUsers.length - 1; i > 0; i--) {
        const randomUuid = uuidv4();

        const j = parseInt(randomUuid.split('-')[0], 16) % (i + 1);

        [shuffledUsers[i], shuffledUsers[j]] = [shuffledUsers[j], shuffledUsers[i]]; // Swap elements
    }

    const limitedUserCount = Math.min(userCount, shuffledUsers.length);
    
    return shuffledUsers.slice(0, limitedUserCount);
};

export const generateRandomDate = () => {
    const randomDays = getRandomInt(1, 30);
    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + randomDays);

    return dueDate.toISOString();
}

export const generateTasks = (count = 36) => {
    const levels = ['Level 1', 'Level 2', 'Level 3', 'Level 4'];
    const tasks: TaskDataType[] = [];

    for (let i = 0; i < count; i++) {
        const taskId = generateCustomTaskId(i);
        const taskName = `Task ${i + 1}`;
        const description = generateTaskDescription(taskName);

        const associatedPipelines = generateRandomPipelines(getRandomInt(20, 30));
        const individualUsers = generateRandomUsers(getRandomInt(13, 20));
        const selectedLevels = levels.slice(0, getRandomInt(1, 4));

        const task = {
            task_id: taskId,
            task_name: taskName,
            created_at: generateRandomDate(),
            updated_at: generateRandomDate(),
            asso_pipelines: associatedPipelines,
            individualUser: individualUsers,
            levels: selectedLevels,
            task_desc: description,
        };

        tasks.push(task);
    }

    return tasks;
};
