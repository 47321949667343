import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { DealType, generatePipeline, PipelineType } from "../../cfpipelines1/src/utils/generatePipeline";
import { toast } from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  singleDeal: DealType
  isMoveDeal: boolean
  isDeleteDeal: boolean
  stagesId: string[]
  dest_stage_id: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfDealDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      singleDeal: { deal_id: '', deal_name: '', deal_desc: '', due_date: ' ', priority: '', created_at: '', asso_user: { source: '', user_name: '' }, pipeline_id: '', stage_id: '' },
      isMoveDeal: false,
      isDeleteDeal: false,
      dest_stage_id: '',
      stagesId: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const { pipelineId } = Object.fromEntries(new URLSearchParams(window.location.search));
    const allPipelineData: PipelineType[] = generatePipeline()
    const storedDeal = JSON.parse(localStorage.getItem("deal") || "{}");
    const singlePipelineData = allPipelineData.find(item => item.id == pipelineId)
    const allStagesIds = singlePipelineData?.stages.map(stage => stage.id)
    if (allStagesIds){
      this.setState({ singleDeal: storedDeal, stagesId: allStagesIds })
  }else{
this.setState({singleDeal: storedDeal, stagesId:["#S00000001",'#S00000002',"#S00000003"]})
  }}

  toggleMoveDeal = () => {
    this.setState(prevState => ({ isMoveDeal: !prevState.isMoveDeal }))
  }

  handleMoveDeal = (event: any) => {
    event.preventDefault()
    const { singleDeal, dest_stage_id } = this.state
    const updatedDeal = { ...singleDeal }
    updatedDeal.stage_id = dest_stage_id
    this.setState({ singleDeal: updatedDeal })
    localStorage.setItem("deal", JSON.stringify(updatedDeal))
    toast.success(`Deal moved successfully to stage ${this.state.dest_stage_id}`, { style: { fontSize: "1.6rem" } })
    this.toggleMoveDeal()
  }

  handleChangeMoveDeal = (event: any, newValue: any) => {
    this.setState({ dest_stage_id: newValue });
  };

  handleEditDeal = () => {
    localStorage.setItem('deal', JSON.stringify(this.state.singleDeal))
    this.props.navigation.navigate('EditCfDealDetails', { dealId: this.state.singleDeal.deal_id, stageId: this.state.singleDeal.stage_id, pipelineId: this.state.singleDeal.pipeline_id })
  }
  toggleDeleteDeal = () => {
    this.setState((prevState) => ({ isDeleteDeal: !prevState.isDeleteDeal }))
  }
  handleDeleteDeal = () => {
    toast.success("Deal Deleted Successfully", { style: { fontSize: '1.6rem' } })
    localStorage.removeItem("deal")
    this.props.navigation.navigate("Cfstage1details", { stageId: this.state.singleDeal.stage_id, pipelineId: this.state.singleDeal.pipeline_id })
  }
  // Customizable Area End
}
