import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { generatePipeline, PipelineType, StagesDataType } from "../../cfpipelines1/src/utils/generatePipeline";
import { toast } from "react-toastify";
interface EditStageErrorType {
    stage_name?: string
    description?: string
    asso_pipeline?: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    singleStage: StagesDataType
    pipelinesId: string[]
    stage_name: string,
    description: string
    asso_pipeline: string
    editStageErrors: EditStageErrorType
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EditStageDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            singleStage: { id: '', created_at: '', permission_level: '', pipeline_id: "", deals: [], description: '', rule_builder_id: null, stage_name: '', task: null, updated_at: '' },
            pipelinesId: [],
            stage_name: '',
            description: '',
            asso_pipeline: '',
            editStageErrors: {}
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        const { stageId, pipelineId } = Object.fromEntries(new URLSearchParams(window.location.search));
        const storageStage = JSON.parse(localStorage.getItem("stage") || "{}");
        const allPipelinesData: PipelineType[] = generatePipeline();

        const pipelinesIdsData = allPipelinesData.map(item => item.id)
        const singlePipelineDataValue = allPipelinesData.find(pipeline => pipeline.id == pipelineId)
        const singleStageData = singlePipelineDataValue?.stages.find(stage => stage.id == stageId)
        if (singleStageData) {
            this.setState({ singleStage: singleStageData, stage_name: singleStageData.stage_name, description: singleStageData.description, asso_pipeline: singleStageData.pipeline_id,pipelinesId: pipelinesIdsData })
        } else {
            this.setState({ stage_name: storageStage.stage_name, singleStage: storageStage, asso_pipeline: storageStage.pipeline_id, description: storageStage.description,pipelinesId: [...pipelinesIdsData, pipelineId] })
        }
        this.setState({  })
    }

    handleChange = (event: any) => {
        const { value, name } = event.target;
        this.setState((prevState) => {
            const updatedEditStageErrors = { ...prevState.editStageErrors };
      
            if (name === "stage_name" && value.length <= 100) {
              delete updatedEditStageErrors.stage_name;
            }
      
            if (name === "description" && value.length <= 300) {
              delete updatedEditStageErrors.description;
            }
      
            return {
              ...prevState,
              [name]: value,
              editStageErrors: updatedEditStageErrors,
            };
          });
    }

    handleEditStage = () => {
        const { stage_name, description, asso_pipeline, singleStage } = this.state
        const addStageErrorMessages = this.validateEditStageFields(stage_name, description);
        if (Object.keys(addStageErrorMessages).length > 0) {
            this.setState({ editStageErrors: addStageErrorMessages });
            return;
        }
        const updateStage = { ...singleStage };
        updateStage.stage_name = stage_name
        updateStage.description = description
        updateStage.pipeline_id = asso_pipeline
        this.setState({ singleStage: updateStage })
        localStorage.setItem("stage", JSON.stringify(updateStage))
        toast.success("Stage edited successfully", { style: { fontSize: '1.6rem' } })
        this.props.navigation.navigate('Cfstage1details', { stageId: updateStage.id, pipelineId: updateStage.pipeline_id })
    }

    isEditStageFormComplete = () => {
        const { stage_name, description, asso_pipeline } = this.state;
        return (
            asso_pipeline && stage_name &&
            description
        );
    };

    validateEditStageFields = (stage_name: string, stage_description: string) => {
        const errors: EditStageErrorType = {};

        if (stage_description.length > 300) {
            errors.description = "Stage description cannot exceed 300 characters.";
        }

        if (stage_name.length > 100) {
            errors.stage_name = "Stage name cannot exceed 100 characters.";
        }

        return errors;
    };
    // Customizable Area End
}
