import React from "react";
// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import { styled } from "@mui/styles";
import { Modal, Box, TextField, IconButton, MenuItem, Button, Typography, TypographyProps, Divider, Radio, FormControlLabel, Checkbox, Autocomplete, Paper } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
type Option = { label: string; value?: string; isNew?: boolean; id?: number | null };
// Customizable Area End

import CreatePipelineRuleController, {
  Props,
} from "./CreatePipelineRuleController";

export default class PipelineRule extends CreatePipelineRuleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTypography = (text: string, textAlign: TypographyProps['align'] = 'left') => {
    return (
      <Typography variant="body1" style={{ margin: "0.5rem 0", textAlign, color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
        {text}
      </Typography>
    )
  }

  addEditAutocomplete = (
    id: string,
    value: Option[],
    options: Option[],
    placeholder: string,
    handleAddNewToggle: () => void,
    handleEditToggle: (value: Option) => void,
    text: string
  ) => {
    const iconAutoComplete = <CheckBoxOutlineBlankIcon style={{ height: "2rem", width: "2rem" }} />;
    const checkedIconAutoComplete = <CheckBoxIcon style={{ height: "2rem", width: "2rem", color: "#1A4A4299" }} />;
    return (
      <Autocomplete
        multiple
        id={id}
        onChange={this.handleAutoComplete(id)}
        value={value}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option: Option) => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        PaperComponent={({ children }) => (
          <Paper
            elevation={2}
            sx={{
              border: '0.1rem solid #A8A29E',
              borderRadius: '0.8rem',
              marginTop: '0.2rem',
              padding: '0.5rem'
            }}
          >
            {children}
          </Paper>
        )}
        renderOption={(props, option, { selected }, index) => {
          const generateSecureRandomKey = () => {
            const array = new Uint32Array(1);
            window.crypto.getRandomValues(array);
            return array[0].toString();
          };
          if (option?.isNew) {
            return (
              <li
                {...props}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddNewToggle();
                }}
                style={{
                  fontSize: '1.6rem',
                  backgroundColor: 'transparent',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#1A4A42',
                  fontWeight: 700,
                }}
              >
                <AddCircleOutlineOutlinedIcon style={{ fontSize: '2rem', marginRight: '0.5rem' }} />
                Add New {text}
              </li>
            );
          }

          return (
            <li
              {...props}
              key={`${option.label}-${index}-${generateSecureRandomKey()}`}
              style={{
                fontSize: '1.6rem',
                backgroundColor: selected ? '#1A4A421A' : 'transparent',
                fontWeight: 700,
                color: selected ? '#1A4A42' : '#0F172A',
                display: 'flex',
                justifyContent: 'space-between',
                gap: "0.5rem",
                alignItems: 'center',
                marginLeft: '-10px',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  icon={iconAutoComplete}
                  checkedIcon={checkedIconAutoComplete}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </div>
              <EditIcon
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditToggle(option);
                }}
                style={{ ...iconStyle, fontSize: '2rem' }}
              />
            </li>
          );
        }}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={Array.isArray(params.InputProps.startAdornment) && params.InputProps.startAdornment.length > 0 ? '' : placeholder}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              ...params.InputProps,
              sx: {
                fontWeight: 400,
                minHeight: '9rem !important',
                maxHeight: '9rem !important',
                overflowY: 'auto',
                paddingLeft: '0.3rem !important',
                fontSize: '1.6rem',
                alignItems: 'center',
                display: "flex",
                flexWrap: 'wrap',
                '.css-18bmrv6-MuiButtonBase-root-MuiChip-root': { fontSize: '1.6rem' },
                '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: 0,
                height: '9rem !important',
                overflow: 'auto',
                border: '0.1rem solid rgba(0, 0, 0, 0.23)'
              },
              '& .MuiOutlinedInput-root:hover': {
                border: '1px solid rgba(0, 0, 0, 0.87)'
              },
              '& .css-as6vcu-MuiInputBase-root-MuiOutlinedInput-root:focus-within': {
                border: '2px solid rgb(25, 118, 210)'
              },
            }}
          />
        )}
      />
    )
  }
  renderStaticTextField = (
    name: string,
    testId: string,
    minHeight: string,
    lineHeight: string,
    defaultValue: string
  ) => {
    return (
      <TextField
        name={name}
        data-test-id={testId}
        fullWidth
        multiline
        style={{ marginBottom: "0.8rem", boxSizing: 'border-box' }}
        inputProps={{
          readOnly: true,
          sx: {
            minHeight: `${minHeight} !important`,
            fontWeight: 400,
            fontSize: '1.6rem',
            display: 'flex',
            alignItems: 'center',
            flexWrap: "wrap",
            color: "#999",
            lineHeight: `${lineHeight}`,
          },
        }}
        InputLabelProps={{ shrink: false }}
        defaultValue={defaultValue}
      />
    );
  };

  renderTextFieldWithSelect = (
    name: string,
    value: string,
    staticText: string,
    optionsData: { value: string; label: string }[],
  ) => {
    return (
      <TextField
        name={name}
        data-test-id={name}
        value={value || ''}
        onChange={this.handleChange}
        select
        fullWidth
        style={{ marginBottom: "0.8rem" }}
        inputProps={{
          sx: {
            minHeight: '8rem !important',
            fontWeight: 400,
            fontSize: "1.6rem",
            display: 'flex',
            alignItems: 'center',
            flexWrap: "wrap",
            whiteSpace: 'normal',
            overflowWrap: 'break-word',
            wordBreak: 'break-word'
          },
        }}
        InputLabelProps={{ shrink: false }}
        SelectProps={{
          displayEmpty: true,
          renderValue: (selected: unknown) => {
            const selectedOptionData = optionsData.find(item => item.value === selected);
            if (!selectedOptionData) {
              return (
                <span style={{ color: "#999", whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '3rem' }}>
                  {staticText}
                </span>
              );
            }
            return (
              <div style={{
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                lineHeight: '3rem',
                maxWidth: '100%',
                display: 'block',
              }}>
                {selectedOptionData.label}
              </div>
            )
          },
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: '200px',
                overflowY: 'auto',
                '& .MuiMenuItem-root': {
                  whiteSpace: 'normal',
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                },
              },
            },
          },
        }}
      >
        {optionsData.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            sx={{
              fontSize: '1.6rem',
              fontWeight: 400,
              backgroundColor: value === item.value ? '#1A4A421A' : 'white',
              '&:hover': {
                backgroundColor: value === item.value ? '#1A4A421A' : '#f0f0f0',
              },
            }}
          >
            <FormControlLabel
              value={item.value}
              control={
                <Radio
                  checked={value === item.value}
                  onChange={this.handleChange}
                  value={item.value}
                  name={name}
                  sx={{
                    padding: '0 0.8rem',
                    '&.Mui-checked': {
                      color: '#1A4A42',
                    },
                    '&.MuiRadio-root': {
                      color: '#1A4A42',
                    },
                    '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                      backgroundColor: '#1A4A421A',
                    },
                  }}
                />
              }
              label={
                <Typography sx={{
                  fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42", whiteSpace: 'normal',
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word'
                }}>
                  {item.label}
                </Typography>
              }
              sx={{
                fontSize: '1.6rem', fontWeight: 400, whiteSpace: 'normal',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            />
          </MenuItem>
        ))}
      </TextField>
    );
  };

  renderModalTopbar = (handleModalToggle: () => void) => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={1}>
        <IconButton onClick={handleModalToggle}>
          <CloseIcon style={{ height: "2rem", width: '2rem' }} />
        </IconButton>
      </Box>
    )
  }

  renderModalTitle = (title: string) => {
    return (
      <Typography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.6rem', marginBottom: '2rem' }}>{title}</Typography>
    )
  }

  renderModalHorizontalLabel = (label: string) => {
    return (
      <Typography variant="body1" sx={{ flex: 1, color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem', marginRight: '1rem' }}>
        {label}
      </Typography>
    )
  }

  renderModalVerticalLabel = (label: string) => {
    return (
      <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
        {label}
      </Typography>
    )
  }

  renderModalTextField = (name: string,
    value: string,
    optionsField: { value: string; label: string }[] | undefined,
    placeholder: string,
    disabled: boolean = false,
    previousValue: string = '',
  ) => {
    return (
      <TextField
        style={{ flex: 3 }}
        name={name}
        data-test-id={name}
        value={value || previousValue}
        onChange={this.handleChangeModalValue}
        select
        disabled={disabled}
        fullWidth
        inputProps={{
          sx: {
            padding: '1rem 0.8rem',
            fontSize: '1.6rem',
            fontWeight: 400,
            display: 'flex',
            alignItems: 'center',
          },
        }}
        InputLabelProps={{ shrink: false }}
        SelectProps={{
          displayEmpty: true,
          renderValue: (selected: unknown) => {
            const selectedOption = optionsField && optionsField.find(item => item.value === selected);
            if (!selectedOption) {
              return <span style={{ color: "#999" }}>{placeholder}</span>;
            }
            return <div style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              lineHeight: '2rem',
              maxWidth: '100%',
              display: 'block',
            }}>
              {selectedOption.label}
            </div>
          },
        }}
      >
        {optionsField && optionsField.map((optionItem, index) => (
          <MenuItem key={index} value={optionItem.value}
            sx={{
              fontSize: '1.6rem', fontWeight: 400, backgroundColor: value === optionItem.value ? '#1A4A421A' : 'white',
              '&:hover': {
                backgroundColor: value === optionItem.value ? '#1A4A421A' : '#f0f0f0',
              },
            }}
          >
            <FormControlLabel
              value={optionItem.value}
              control={<Radio checked={value === optionItem.value}
                onChange={this.handleChange}
                value={optionItem.value}
                name="triggerName"
                sx={{
                  padding: '0 0.8rem',
                  '&.Mui-checked': {
                    color: '#1A4A42',
                  },
                  '&.MuiRadio-root': {
                    color: '#1A4A42',
                  },
                  '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                    backgroundColor: '#1A4A421A',
                  },
                }}

              />}
              label={<Typography sx={{ fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>
                {optionItem.label}
              </Typography>}
              sx={{
                fontSize: '1.6rem',
                fontWeight: 400
              }}

            />
          </MenuItem>
        ))}
      </TextField>
    )
  }

  addButtonComponent = (handleAdd: (e: any) => void, title: string) => {
    return (
      <Box>
        <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <Button onClick={handleAdd} variant="contained" sx={{
            backgroundColor: '#1A4A42',
            color: 'white',
            padding: '0.8rem 0',
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            width: "100%",
            fontSize: '1.4rem'
          }}>
            Add {title}
          </Button>
        </Box>
      </Box>
    )
  }

  editButtonComponent = (handleEditToggle: () => void, handleSave: (e: any) => void) => {
    return (
      <Box>
        <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
        <Box display="flex" justifyContent="center" gap={2} mt={3}>
          <Button onClick={handleEditToggle} variant="contained" sx={{
            backgroundColor: '#1A4A421A',
            color: '#1A4A4299',
            padding: '0.8rem 0',
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            width: "12rem",
            fontSize: '1.2rem'
          }}>
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" sx={{
            backgroundColor: '#1A4A42',
            color: 'white',
            padding: '0.8rem 0',
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            width: "12rem",
            fontSize: '1.2rem'
          }}>
            Save
          </Button>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const pipelineData = [{ label: this.state.pipelineData.attributes.pipeline_name, value: this.state.pipelineData.id }];
    const icon = <CheckBoxOutlineBlankIcon style={{ height: "2rem", width: "2rem" }} />;
    const checkedIcon = <CheckBoxIcon style={{ height: "2rem", width: "2rem", color: "#1A4A4299" }} />;
    const AddNewTriggerOption: Option = { label: 'Add New Trigger', isNew: true };
    const AddNewEventOption: Option = { label: 'Add New Event', isNew: true };
    const triggerData = this.state.triggersData.map(triggerItem => ({ label: triggerItem.trigger_name, value: triggerItem.trigger_name, id: triggerItem.id }))
    const eventData = this.state.eventsData.map(eventItem => ({ label: eventItem.event_name, value: eventItem.event_name, id: eventItem.id }))
    const triggerOptions = [
      { label: 'Field is changed', value: 'field_is_changed' },
      { label: "Session is Booked", value: "session_is_booked" },
      { label: 'Session Occurs', value: 'session_occurs' },
      { label: "Appointment Is", value: "appointment_is" },
      { label: "Deal is Moved", value: "deal_is_moved" },
      { label: 'Deal is Assigned', value: 'deal_is_assigned' },
      { label: "Due Date", value: "due_date" },
    ]
    const eventOptions = [
      { label: "Assign to a CC / Task List", value: "assign_to_a_cc_task_list" },
      { label: "Create Deal", value: "create_deal" },
      { label: "Move Deal", value: "move_deal" },
      { label: "Set a Due Date", value: "set_a_due_date" },
      { label: "Send Email/SMS", value: "send_email_sms" },
      { label: "Set/Change Priority", value: "set_change_priority" },
      { label: "Set a Reminder", value: "set_a_reminder" },
      { label: "Mark Deal as Complete", value: "mark_deal_as_complete" },
      { label: "Change a Field", value: "change_a_field" },
      { label: "Cancel Appointment", value: "cancel_appointment" },
    ];

    const triggerType = [
      { label: "Session is Booked Equal to 000001 Any Day from Now", value: "session_is_booked_equal_to_000001_any_day_from_now" },
      { label: "Session is Booked Equal to 000002 Any Day from Now", value: "session_is_booked_equal_to_000002_any_day_from_now" },
      { label: "Session is Booked Equal to 000003 Any Day from Now", value: "session_is_booked_equal_to_000003_any_day_from_now" },
      { label: "Session is Booked Equal to 000004 Any Day from Now", value: "session_is_booked_equal_to_000004_any_day_from_now" },
      { label: "Session is Booked Equal to 000005 Any Day from Now", value: "session_is_booked_equal_to_000005_any_day_from_now" }
    ];

    const afterDayType = [
      { label: "Immediately", value: "immediately" },
      { label: "After 30 min", value: "after_30_min" },
      { label: "After 45 min", value: "after_45_min" },
      { label: "After 1 hr", value: "after_1_hr" },
      { label: "After 2 hr", value: "after_2_hr" },
    ];
    return (
      <PipelineRuleContainer>
        <TopNav navigation={this.props.navigation} id="pipelines" />
        <Divider sx={dividerStyle} />
        <Typography marginBottom={2} variant="h6" sx={{ fontWeight: 600, fontSize: '2rem', color: "#292524" }}>Rule Builder</Typography>
        <Box component="form" noValidate autoComplete="off" onSubmit={this.handleCreateRule}>
          {this.renderTypography("Rule Name")}
          <TextField
            name="rule_name"
            data-test-id="rule_name"
            value={this.state.rule_name}
            onChange={this.handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            placeholder="Type rule name"
            style={{ marginBottom: "0.8rem" }}
            inputProps={{
              sx: {
                padding: '0.8rem',
                fontWeight: 400,
                fontSize: '1.6rem'
              },
            }}
          />

          {this.renderTypography("Apply to pipeline")}
          <TextField
            name="pipeline_name"
            data-test-id="pipeline_name"
            value={this.state.pipeline_name || ''}
            onChange={this.handleChange}
            select
            fullWidth
            style={{ marginBottom: "0.8rem" }}
            inputProps={{
              sx: {
                padding: '0.8rem !important',
                fontWeight: 400,
                fontSize: "1.6rem",
                display: 'flex',
                alignItems: 'center',
              },
            }}
            InputLabelProps={{ shrink: false }}
            SelectProps={{
              displayEmpty: true,

              renderValue: (selected: unknown) => {
                const selectedPipeline = pipelineData.find(item => item.value === selected);
                if (!selectedPipeline) {
                  return <span style={{ color: "#999" }}>select</span>;
                }
                return selectedPipeline.label;
              },
            }}
          >
            {pipelineData.map((item, index) => (
              <MenuItem key={index} value={item.value} sx={{
                fontSize: '1.6rem', fontWeight: 400, backgroundColor: this.state.pipeline_name === item.value ? '#1A4A421A' : 'white',
                '&:hover': {
                  backgroundColor: this.state.pipeline_name === item.value ? '#1A4A421A' : '#f0f0f0',
                },
              }}>
                <FormControlLabel
                  value={item.value}
                  control={
                    <Radio
                      checked={this.state.pipeline_name === item.value}
                      onChange={this.handleChange}
                      value={item.value}
                      name="pipeline_name"
                      sx={{
                        padding: '0 0.8rem',
                        '&.Mui-checked': {
                          color: '#1A4A42',
                        },
                        '&.MuiRadio-root': {
                          color: '#1A4A42',
                        },
                        '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                          backgroundColor: '#1A4A421A',
                        },
                      }}
                    />
                  }
                  label={<Typography sx={{ fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>
                    {item.label}
                  </Typography>}
                  sx={{
                    fontSize: '1.6rem',
                    fontWeight: 400
                  }}
                />
              </MenuItem>
            ))}
          </TextField>
          {this.renderTypography("Apply to stage")}
          <Autocomplete
            multiple
            id="autocomplete-stages"
            options={this.state.stages}
            onChange={this.handleAutoComplete("selectedStages")}
            isOptionEqualToValue={(option, value) => option.stage_name === value.stage_name}
            value={this.state.selectedStages}
            disableCloseOnSelect
            getOptionLabel={(option) => option.stage_name}
            renderOption={(props, option, { selected }) => {
              const { ...optionProps } = props;
              return (
                <li {...optionProps} style={{ fontSize: '1.6rem', backgroundColor: selected ? '#1A4A421A' : 'transparent', fontWeight: 700, color: selected ? "#1A4A42" : "#0F172A" }}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.stage_name}
                </li>
              )
            }}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                style={{ marginBottom: "0.8rem" }}
                {...params}
                placeholder={
                  Array.isArray(params.InputProps.startAdornment) && params.InputProps.startAdornment.length > 0
                    ? ''
                    : 'select'
                }
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    fontWeight: 400,
                    paddingLeft: '0.3rem !important',
                    fontSize: "1.6rem",
                    '.css-18bmrv6-MuiButtonBase-root-MuiChip-root': {
                      fontSize: "1.6rem",
                      padding: '0',
                    }
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                  },
                }}
              />
            )}
          />
          <Box display="flex" justifyContent="space-between" gap="1.5rem" flexWrap="wrap">
            <Box style={{ flex: 1, display: "flex", flexDirection: "column", gap: '1.5rem' }}>
              <Box>
                {this.renderTypography("When")}
                {this.addEditAutocomplete("selectedTriggers", this.state.selectedTriggers, [AddNewTriggerOption, ...triggerData], "Select Trigger (Multiselect)", this.handleTriggerToggle, this.handleEditTriggerToggle, "Trigger")}
              </Box>
              <Box>
                {this.renderTypography("And", "center")}
                {this.renderTextFieldWithSelect("triggerType", this.state.triggerType, "Session is Booked Equal to 000001 Any Day from Now", triggerType)}
              </Box>
            </Box>
            <Box style={{ flex: 1, display: "flex", flexDirection: "column", gap: '1.5rem' }}>
              <Box>
                {this.renderTypography("After")}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={this.state.after}
                    onChange={this.handleDayChange}
                    name="after"
                    format="DD/MM/YYYY"
                    sx={{ width: '100%', fontSize: '1.6rem' }}
                    slots={{
                      textField: (params) => (
                        <TextField
                          {...params}
                          placeholder="Select Time Delay if Any"
                          sx={{
                            width: '100%',
                            fontSize: '1.6rem',
                          }}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              fontWeight: 400,
                              paddingLeft: "0.8rem !important",
                              minHeight: '9rem !important',
                              fontSize: "1.6rem",
                              '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                                padding: '0',
                                fontSize: "1.6rem"
                              }
                            },
                          }}
                        />
                      ),
                    }}

                    slotProps={{
                      popper: {
                        sx: {
                          '& .MuiPaper-root': {
                            minWidth: '30rem',
                            border: '0.1rem solid #E2E8F0',
                            marginTop: '0.2rem',
                            borderRadius: '0.8rem'
                          },
                          '& .MuiTypography-root': {
                            fontSize: '1.8rem !important',
                          },
                          '& .MuiPickersArrowSwitcher-button': {
                            fontSize: '2rem !important',
                            '& .MuiSvgIcon-root': {
                              fontSize: '2rem !important',
                            },
                          },
                          '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                            fontSize: '1.6rem'
                          },
                          '& .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon': {
                            width: '2rem',
                            height: '2rem'
                          },
                          '& .css-13m8yui-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
                            border: 'none',
                            fontWeight: 700
                          },

                          '& .css-1b2k5pm-MuiPickersYear-yearButton': {
                            fontSize: '1.4rem'
                          },
                          '& .MuiDayCalendar-weekDayLabel': {
                            fontSize: '1.4rem !important',
                            fontWeight: 400
                          },
                        },
                      },
                      day: {
                        sx: {
                          fontSize: '1.4rem',
                        },
                      },
                    }}

                  />
                </LocalizationProvider>
              </Box>
              <Box>
                {this.renderTypography("And", "center")}
                <Box display="flex" flexDirection="column" gap={2}>
                  {this.renderTextFieldWithSelect("delay1", this.state.delay1, "Immediately", afterDayType)}
                  {this.renderTextFieldWithSelect("delay2", this.state.delay2, "After 30 min", afterDayType)}
                </Box>
              </Box>
            </Box>
            <Box style={{ flex: 1, display: "flex", flexDirection: "column", gap: '1.5rem' }}>
              <Box>
                {this.renderTypography("Do this")}
                {this.addEditAutocomplete("selectedEvents", this.state.selectedEvents, [AddNewEventOption, ...eventData], "Select Event", this.handleEventToggle, this.handleEditEventToggle, "Event")}
              </Box>
              <Box>
                {this.renderTypography("And", "center")}
                <Box display="flex" flexDirection="column" gap={2}>
                  {this.renderStaticTextField("MoveDeal", "Movedeal", '8rem', '3rem', "Move Deal Stage:\nFirst Session Set")}
                  {this.renderStaticTextField("sendmail", "sendmail", '4rem', '2rem', "Send Email\n[Welcome Template] Subject:\n[From Template] Body: [From\nTemplate]")}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
            <Button variant="contained" sx={{
              backgroundColor: '#1A4A421A',
              color: '#1A4A4299',
              padding: '1rem 6rem',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.4rem'
            }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" sx={{
              backgroundColor: '#1A4A42',
              color: 'white',
              padding: '1rem 6rem',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.4rem'
            }}>
              Create Rule
            </Button>
          </Box>
        </Box>
        <Modal
          open={this.state.addTriggerModal}
          onClose={this.handleTriggerToggle}
          aria-labelledby="add-trigger-modal"
          aria-describedby="add-trigger-form"
        >
          <Box sx={modalStyle}>
            <Box>
              {this.renderModalTopbar(this.handleTriggerToggle)}
              <Box>
                {this.renderModalTitle("Trigger Options")}
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Trigger:")}
                  {this.renderModalTextField("triggerName", this.state.triggerName, triggerOptions, "Select Trigger")}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Select Type:")}
                  {this.renderModalTextField("triggerSelect1", this.state.triggerSelect1, this.state.tsel1data, "Client", !this.state.isTriggerSelect1Enabled)}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Select Input:")}
                  {this.renderModalTextField("triggerInput1", this.state.triggerInput1, this.state.tinput1Data, "company_name", !this.state.isTriggerInput1Enabled)}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Select property:")}
                  {this.renderModalTextField("triggerSelect2", this.state.triggerSelect2, this.state.tsel2sata, "Date of Session is ANY", !this.state.isTriggerSelect2Enabled)}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Input:")}
                  <TextField
                    name="triggerInput2"
                    data-test-id="triggerInput2"
                    value={this.state.triggerInput2}
                    onChange={this.handleChangeModalValue}
                    fullWidth
                    disabled={!this.state.isTriggerInput2Enabled}
                    InputLabelProps={{ shrink: false }}
                    placeholder="NA"
                    style={{ marginBottom: "0.8rem", flex: 3 }}
                    inputProps={{
                      sx: {
                        padding: '1rem 0.8rem',
                        fontWeight: 400,
                        fontSize: '1.6rem'
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            {this.addButtonComponent(this.handleAddTrigger, "Trigger")}
          </Box>
        </Modal>
        <Modal
          open={this.state.addEventModal}
          onClose={this.handleEventToggle}
          aria-labelledby="add-event-modal"
          aria-describedby="add-event-form"
        >
          <Box sx={modalStyle}>
            <Box>
              {this.renderModalTopbar(this.handleEventToggle)}
              <Box>
                {this.renderModalTitle("Event Options")}
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Event:")}
                  {this.renderModalTextField("eventName", this.state.eventName, eventOptions, "Move Deal")}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Select:")}
                  {this.renderModalTextField("eventSelect", this.state.eventSelect, this.state.esel1Data, "Deal Type", !this.state.isEventSelectEnabled)}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Input:")}
                  {this.renderModalTextField("eventInput", this.state.eventInput, this.state.einput1Data, "First Session Set Stage", !this.state.isEventInputEnabled)}
                </Box>
              </Box>
            </Box>
            {this.addButtonComponent(this.handleAddEvent, "Event")}
          </Box>
        </Modal>
        <Modal
          open={this.state.editTriggerModal}
          onClose={this.handleEditTriggerToggle}
          aria-labelledby="edit-trigger-modal"
          aria-describedby="edit-trigger-form"
        >
          <Box sx={modalStyle}>
            <Box>
              {this.renderModalTopbar(this.handleEditTriggerToggle)}
              <Box>
                {this.renderModalTitle("Edit Trigger")}
                {this.renderModalVerticalLabel("Trigger")}
                {this.renderModalTextField("editTriggerName", this.state.editTriggerName, triggerOptions, "Select Trigger", false, this.state.editTriggerPrevValue.trigger_name)}
                {this.renderModalVerticalLabel("Select Type")}
                {this.renderModalTextField("editTriggerSelect1", this.state.editTriggerSelect1, this.state.tsel1data, "Client", false, this.state.editTriggerPrevValue.selection1 || '')}
                {this.renderModalVerticalLabel("Select Input")}
                {this.renderModalTextField("editTriggerInput1", this.state.editTriggerInput1, this.state.tinput1Data, "company_name", false, this.state.editTriggerPrevValue.value1)}
                {this.renderModalVerticalLabel("Select property conditions")}
                {this.renderModalTextField("editTriggerSelect2", this.state.editTriggerSelect2, this.state.tsel2sata, "Date of Session is ANY", false, this.state.editTriggerPrevValue.selection2 || '')}
                {this.renderModalVerticalLabel("Input")}
                <TextField
                  name="editTriggerInput2"
                  data-test-id="editTriggerInput2"
                  value={'' || this.state.editTriggerInput2}
                  onChange={this.handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  placeholder="NA"
                  style={{ marginBottom: "0.8rem", flex: 3 }}
                  inputProps={{
                    sx: {
                      padding: '1rem 0.8rem',
                      fontWeight: 400,
                      fontSize: '1.6rem'
                    },
                  }}
                />
              </Box>
            </Box>
            {this.editButtonComponent(this.handleEditTriggerToggle, this.handleEditTriggerSave)}
          </Box>
        </Modal>
        <Modal
          open={this.state.editEventModal}
          onClose={this.handleEditEventToggle}
          aria-labelledby="edit-event-modal"
          aria-describedby="edit-event-form"
        >
          <Box sx={modalStyle}>
            <Box>
              {this.renderModalTopbar(this.handleEditEventToggle)}
              <Box>
                {this.renderModalTitle("Edit Event")}
                {this.renderModalVerticalLabel("Assign to")}
                {this.renderModalTextField("editEventName", this.state.editEventName, eventOptions, "Move Deal", false, this.state.editEventPrevValue.event_name)}
                {this.renderModalVerticalLabel("Select task")}
                {this.renderModalTextField("editEventSelect", this.state.editEventSelect, this.state.esel1Data, "Deal Type", false, this.state.editEventPrevValue.dropdown || '')}
                {this.renderModalVerticalLabel("Select input")}
                {this.renderModalTextField("editEventInput", this.state.editEventInput, this.state.einput1Data, "First Session Set Stage", false, this.state.editEventPrevValue.value || '')}
              </Box>
            </Box>
            {this.editButtonComponent(this.handleEditEventToggle, this.handleEditEventSave)}
          </Box>
        </Modal>
      </PipelineRuleContainer >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const PipelineRuleContainer = styled("div")({
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  fontFamily: "Raleway, sans-serif",
  boxSizing: "border-box",
});
const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};
const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#064E3B",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};
const modalStyle = {
  position: 'absolute',
  top: '0',
  right: '0',
  height: '100vh',
  width: '50rem',
  bgcolor: 'background.paper',
  boxShadow: 2,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: "space-between",
  padding: '1rem 4.8rem 1rem',
};
// Customizable Area End
