import React from "react";
// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import { Box, Divider, Button, Typography, TextField, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search'
import dayjs from 'dayjs'
// Customizable Area End

import CfCreateEditDealController, {
    Props,
} from "./CfCreateEditDealController";

export default class CfCreateEditDeal extends CfCreateEditDealController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { navigation, buttonText, description, title, asso_pipeline, selectedPriority, users, stageId, deal_name, due_date, assigned_to, handleAssignedChange, handleChange, handleDayChange, handleEditStage, handlePriorityChange, isEditStageFormComplete, } = this.props
        return (
            <Box sx={editStageContainerStyle}>
                <TopNav navigation={navigation} id="pipelines" />
                <Divider sx={editStageDividerStyle} />
                <Box marginBottom={2}>
                    <Box display='flex' alignItems='center' style={{ gap: "1rem" }}>
                        <ArrowBackIcon data-test-id="back-btn" style={{ fontSize: '2rem' }} onClick={() => navigation.goBack()} />
                        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>{title}</Typography>
                    </Box>
                </Box>
                <Box display='flex' flexWrap='wrap' style={{ gap: "1rem" }} marginBottom={1}>
                    <Box style={{ flex: 1 }}>
                        <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                            Deal Name
                        </Typography>
                        <TextField
                            name="deal_name"
                            data-test-id="deal_name"
                            value={deal_name}
                            onChange={handleChange}
                            placeholder="Type Deal Name"
                            fullWidth
                            multiline
                            InputLabelProps={{ shrink: false }}
                            style={{ marginBottom: "0.8rem" }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    padding: '1.1rem! important',
                                    height: '4rem !important'
                                }
                            }}
                            inputProps={{
                                sx: {
                                    fontWeight: 400,
                                    fontSize: '1.6rem',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                },
                            }}
                        />
                    </Box>
                    <Box style={{ flex: 1 }}>
                        <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                            Due Date
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={(due_date)}
                                onChange={handleDayChange}
                                name="due_date"
                                minDate={dayjs()}
                                format="MMMM DD, YYYY"
                                sx={{ width: '100%', fontSize: '1.6rem' }}
                                slots={{
                                    textField: (params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                width: '100%',
                                                fontSize: '1.6rem',
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                sx: {
                                                    fontWeight: 400,
                                                    padding: '1rem 1.5rem',
                                                    minHeight: "4rem",
                                                    maxHeight: "4rem",
                                                    paddingLeft: "0.8rem !important",
                                                    fontSize: "1.6rem",
                                                    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                                                        padding: '0',
                                                        fontSize: "1.6rem"
                                                    }
                                                },
                                            }}
                                        />
                                    ),
                                }}

                                slotProps={{
                                    popper: {
                                        sx: {
                                            '& .MuiPaper-root': {
                                                minWidth: '30rem',
                                                marginTop: '0.2rem',
                                                borderRadius: '0.8rem'
                                            },
                                            '& .MuiTypography-root': {
                                                fontSize: '1.8rem !important',
                                            },
                                            '& .MuiPickersArrowSwitcher-button': {
                                                fontSize: '2rem !important',
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: '2rem !important',
                                                },
                                            },
                                            '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                                                fontSize: '1.6rem'
                                            },
                                            '& .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon': {
                                                width: '2rem',
                                                height: '2rem'
                                            },
                                            '& .css-13m8yui-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
                                                border: 'none',
                                                fontWeight: 700
                                            },

                                            '& .css-1b2k5pm-MuiPickersYear-yearButton': {
                                                fontSize: '1.4rem'
                                            },
                                            '& .MuiDayCalendar-weekDayLabel': {
                                                fontSize: '1.4rem !important',
                                                fontWeight: 400
                                            },
                                        },
                                    },
                                    day: {
                                        sx: {
                                            fontSize: '1.4rem',
                                        },
                                    },
                                }}

                            />
                        </LocalizationProvider>
                    </Box>
                </Box>
                <Box display='flex' flexWrap='wrap' style={{ gap: "1rem" }} marginBottom={1}>
                    <Box style={{ flex: 1 }}>
                        <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                            Assigned To
                        </Typography>
                        <TextField
                            name="assigned_to"
                            data-test-id="assigned_to"
                            value={assigned_to}
                            sx={{
                                marginBottom: '0.8rem',
                                '& .MuiSelect-icon': {
                                    display: 'none',
                                },
                                "& .css-cjh5ej-MuiInputBase-root-MuiOutlinedInput-root": {
                                    paddingRight: '0.8rem',
                                    minHeight: "4rem",
                                    maxHeight: "4rem"
                                }
                            }}
                            select
                            fullWidth
                            inputProps={{
                                sx: {
                                    padding: '1rem 0.8rem',
                                    fontSize: '1.6rem',
                                    fontWeight: 400,
                                    borderRadius: '0.8rem',
                                    display: 'flex',
                                    alignItems: 'center',

                                },
                            }}
                            InputLabelProps={{ shrink: false }}
                            InputProps={{
                                endAdornment: (
                                    <SearchIcon
                                        sx={{
                                            color: '#64748B',
                                            cursor: 'pointer',
                                            fontSize: '2rem'
                                        }}
                                    />
                                ),
                            }}
                            SelectProps={{
                                displayEmpty: true,
                                renderValue: () => {
                                    if (!assigned_to.user_name) {
                                        return <span style={{ color: "#999" }}>Search & Select User</span>;
                                    }
                                    return (
                                        <Box display="flex" alignItems="center" style={{ gap: "1rem" }}>
                                            <img
                                                src={assigned_to.source}
                                                alt={assigned_to.user_name}
                                                style={{ width: '24px', height: '24px', borderRadius: '50%', objectFit: 'cover' }}
                                            />
                                            {assigned_to.user_name}
                                        </Box>
                                    );
                                },
                            }}
                        >
                            {users.map((user, index) => (
                                <MenuItem
                                    key={index}
                                    value={user.user_name}
                                    sx={{
                                        fontSize: '1.6rem',
                                        fontWeight: 400,
                                        backgroundColor: assigned_to.user_name === user.user_name ? '#1A4A421A' : 'white',
                                        '&:hover': {
                                            backgroundColor: assigned_to.user_name === user.user_name ? '#1A4A421A' : '#f0f0f0',
                                        },
                                    }}
                                    onClick={() => handleAssignedChange(user)}
                                >
                                    <Box display="flex" alignItems="center" style={{ gap: "1rem" }}>
                                        <img
                                            src={user.source}
                                            alt={user.user_name}
                                            style={{ width: '24px', height: '24px', borderRadius: '50%', objectFit: 'cover' }}
                                        />
                                        {user.user_name}
                                    </Box>
                                </MenuItem>
                            ))}
                        </TextField>

                    </Box>
                    <Box style={{ flex: 1 }}>
                        <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                            Associated Stage ID
                        </Typography>
                        <TextField
                            name="stageId"
                            data-test-id="stageId"
                            value={stageId}
                            onChange={handleChange}
                            fullWidth
                            disabled
                            multiline
                            InputLabelProps={{ shrink: false }}
                            style={{ marginBottom: "0.8rem" }}
                            sx={{
                                '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                                    padding: '1.1rem! important',
                                    minHeight: "4rem",
                                    maxHeight: "4rem",
                                }
                            }}
                            inputProps={{
                                sx: {
                                    fontWeight: 400,
                                    fontSize: '1.6rem',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                },
                            }}
                        />
                    </Box>
                </Box>
                <Box display='flex' flexWrap='wrap' style={{ gap: "1rem" }} marginBottom={1}>
                    <Box style={{ flex: 1 }}>
                        <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                            Associated Pipeline
                        </Typography>
                        <TextField
                            name="asso_pipeline"
                            data-test-id="asso_pipeline"
                            value={asso_pipeline}
                            onChange={handleChange}
                            disabled
                            fullWidth
                            multiline
                            InputLabelProps={{ shrink: false }}
                            style={{ marginBottom: "0.8rem", width: '100%' }}
                            sx={{
                                '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                                    padding: '1.1rem! important',
                                    minHeight: "4rem",
                                    maxHeight: "4rem",
                                }
                            }}
                            inputProps={{
                                sx: {
                                    fontWeight: 400,
                                    fontSize: '1.6rem',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                },
                            }}
                        />
                    </Box>
                    <Box style={{ flex: 1 }}>
                        <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                            Priority
                        </Typography>
                        <Box display="flex" style={{ gap: "1rem" }} flexWrap='wrap'>
                            <Button
                                data-test-id="high-button"
                                variant="contained"
                                onClick={() => handlePriorityChange('High')}
                                style={{
                                    fontSize: '1.4rem',
                                    boxShadow: 'none',
                                    border: ' 0.1rem solid #1A4A4233',
                                    borderRadius: '2.6rem',
                                    fontWeight: 500,
                                    textTransform: 'none',
                                    minHeight: '4rem',
                                    maxHeight: '4rem',
                                    width: "10rem",
                                    boxSizing: 'border-box',
                                    color: selectedPriority === 'High' ? '#FFFFFF' : "#475569",
                                    backgroundColor: selectedPriority === 'High' ? '#1A4A42' : '#1A4A421A',
                                }}
                            >
                                High
                            </Button>

                            <Button
                                data-test-id="medium-button"

                                variant="contained"
                                onClick={() => handlePriorityChange('Medium')}
                                style={{
                                    fontSize: '1.4rem',
                                    border: ' 0.1rem solid #1A4A4233',
                                    borderRadius: '2.6rem',
                                    boxShadow: 'none',
                                    fontWeight: 500,
                                    textTransform: 'none',
                                    minHeight: '4rem',
                                    maxHeight: '4rem',
                                    width: "10rem",
                                    boxSizing: 'border-box',
                                    color: selectedPriority === 'Medium' ? '#FFFFFF' : "#475569",
                                    backgroundColor: selectedPriority === 'Medium' ? '#1A4A42' : '#1A4A421A',
                                }}
                            >
                                Medium
                            </Button>

                            <Button
                                data-test-id="low-button"

                                variant="contained"
                                onClick={() => handlePriorityChange('Low')}
                                style={{
                                    fontSize: '1.4rem',
                                    borderRadius: '2.6rem',
                                    border: ' 0.1rem solid #1A4A4233',
                                    boxShadow: 'none',
                                    fontWeight: 500,
                                    textTransform: 'none',
                                    minHeight: '4rem',
                                    maxHeight: '4rem',
                                    width: "10rem",
                                    boxSizing: 'border-box',
                                    color: selectedPriority === 'Low' ? '#FFFFFF' : "#475569",
                                    backgroundColor: selectedPriority === 'Low' ? '#1A4A42' : '#1A4A421A',
                                }}
                            >
                                Low
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                    Description
                </Typography>
                <TextField
                    name="description"
                    data-test-id="stage-desc"
                    value={description}
                    onChange={handleChange}
                    multiline
                    rows={5}
                    fullWidth
                    InputLabelProps={{ shrink: false }}
                    placeholder="Type deal description"
                    style={{ marginBottom: "0.8rem" }}
                    sx={{
                        '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                            padding: '1rem 0.8rem'
                        }
                    }}
                    inputProps={{
                        sx: {
                            fontWeight: 400,
                            fontSize: '1.6rem',

                        },
                    }}
                />
                <Box sx={{ ...editStageBox }}>
                    <Button sx={{
                        width: "100%",
                        padding: "1rem",
                        border: "none",
                        borderRadius: "0.8rem",
                        fontSize: "1.6rem",
                        cursor: "pointer",
                        textTransform: 'none',
                        fontWeight: 600,
                    }} data-test-id="edit-stage-btn" onClick={handleEditStage} disabled={!isEditStageFormComplete()} style={{
                        backgroundColor: isEditStageFormComplete() ? "#1A4A42" : "#A8A29E66",
                        color: isEditStageFormComplete() ? "#fff" : "#78716C",
                        cursor: isEditStageFormComplete() ? "pointer" : "not-allowed",
                    }}>{buttonText}</Button>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const editStageContainerStyle = {
    padding: "2rem 2rem 1rem",
    borderRadius: "1.2rem",
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
    boxSizing: "border-box",
    minHeight: 'calc(100vh - 2.5rem)'
};

const editStageDividerStyle = {
    margin: "1.5rem 0",
    backgroundColor: "#1A4A4233",
};

const editStageBox = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    border: "0.1rem dashed #292524",
    padding: "1.5rem",
    borderRadius: "1.2rem",
    backgroundColor: "#f9f9f9",
    marginTop: "2.5rem"
}
// Customizable Area End
