import React from "react";
// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import { Box, Divider, Typography, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AddIcon from '@mui/icons-material/Add';
import { formatDateTimeField } from "./utils/generatePipeline";
// Customizable Area End

import PipelineDetailController, { Props } from "./PipelineDetailController";

export default class PipelineDetail extends PipelineDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { singlePipeline } = this.state
    return (

      <Box sx={pipelineContainerStyle}>
        <TopNav navigation={this.props.navigation} id="pipelines" />
        <Divider sx={dividerStyle} />
        <Box display="flex" justifyContent='space-between' marginBottom={2}>
          <Box display='flex' alignItems='center' gap={1}>
            <ArrowBackIcon style={{ fontSize: '2rem' }} onClick={this.props.navigation.goBack} />
            <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>{singlePipeline?.pipeline_name}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Button sx={{
              backgroundColor: '#1A4A42',
              color: 'white',
              padding: '0.4rem 1rem',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.2rem',
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: '#1B5C54',
                color: '#fff',
              }
            }}>
              Edit
            </Button>
            <Button sx={{
              backgroundColor: '#EF444433',
              color: '#DC2626',
              padding: '0.4rem 1rem',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.2rem',
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: '#EF444455',
              }
            }}>
              Delete
            </Button>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' flexWrap='wrap' gap={1} marginBottom={1}>
          <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524" }}>Pipeline Details</Typography>
          <Box display='flex' flexDirection='column' flexWrap='wrap' gap={0.5}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>DESCRIPTION</Typography>
            <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#292524" }}>{singlePipeline?.description}</Typography>
          </Box>

        </Box>
        <Box display="flex" flexWrap='wrap'>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>PIPELINE ID</Typography>
            <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{singlePipeline?.id}</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>DATE CREATED</Typography>
            <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{formatDateTimeField(singlePipeline?.created_at)}</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>LAST EDITED</Typography>
            <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{formatDateTimeField(singlePipeline?.updated_at)}</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>ASSOCIATED CLINIC</Typography>
            <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{singlePipeline?.clinic_name}</Typography>
          </Box>
        </Box>
        <Divider sx={innerDividerStyle} />
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524" }}>Visible By</Typography>
          <Box display="flex" marginTop={1}>
            <Box style={{ flex: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>Permission Levels</Typography>
              <Box display="flex" flexDirection='column' sx={{ gap: '0.5rem', marginTop: '0.8rem' }}>
                {singlePipeline?.visibleBy.PermissionLevels.map(permission => {
                  return (
                    <Box key={permission.level_name} display="flex" alignItems='center' gap={1} style={{ border: '0.1rem solid #1A4A4266', borderRadius: '0.7rem', width: '50%', padding: '1rem 0.9rem' }}>
                      <CheckCircleOutlineIcon style={{ fontSize: '1.8rem' }} />
                      <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#292524" }}>{permission.level_name}</Typography>
                    </Box>
                  )
                })}
              </Box>
            </Box>
            <Box style={{ flex: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>Task Groups</Typography>
              <Box display="flex" flexDirection='column' sx={{ gap: '0.5rem', marginTop: '0.8rem' }}>
                {singlePipeline.visibleBy.TaskGroups.length > 0 && (
                  singlePipeline.visibleBy.TaskGroups.map((task, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      gap={1}
                      style={{
                        border: '0.1rem solid #1A4A4266',
                        borderRadius: '0.7rem',
                        width: '50%',
                        padding: '1rem 0.9rem',
                      }}
                    >
                      <PeopleAltOutlinedIcon style={{ fontSize: '1.8rem' }} />
                      <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: '#292524' }}>
                        {task.task_name}
                      </Typography>
                    </Box>
                  ))
                )}
              </Box>
            </Box>
            <Box style={{ flex: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>Individual Users</Typography>
              <Box display="flex" flexDirection='column' sx={{ gap: '0.5rem', marginTop: '0.8rem' }}>
                {singlePipeline.visibleBy.IndividualUsers.length > 0 && (
                  singlePipeline.visibleBy.IndividualUsers.map((user,index) => {
                    return (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        gap={1}
                        style={{
                          border: '0.1rem solid #1A4A4266',
                          borderRadius: '0.7rem',
                          width: '50%',
                          padding: '1rem 0.9rem',
                        }}
                      >
                        <img
                          src={user.source}
                          alt="user image"
                          style={{
                            borderRadius: '50%',
                            height: '2.6rem',
                            width: '2.6rem',
                            objectFit: 'cover',
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 400, fontSize: '1.4rem', color: '#292524' }}
                        >
                          {user.user_name}
                        </Typography>
                      </Box>
                    );
                  })
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider sx={innerDividerStyle} />
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524" }}>Rules ({singlePipeline.rules.length})</Typography>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: 'center' }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              sx={ruleCardStyle}
            >
              <Box sx={addStageStyle}>
                <Typography variant="h5" sx={{ marginBottom: "1rem", fontSize: '1.4rem', fontWeight: 700, color: '#1A4A42' }}>New Rule</Typography>
                <Typography sx={{ textAlign: 'center', marginBottom: "1rem", fontWeight: 400, fontSize: '1.3rem', color: "#292524" }}>Add new rule to this pipeline</Typography>
                <IconButton sx={addButtonStyle}>
                  <AddIcon style={{ height: "2.5rem", width: '2.5rem' }} />
                </IconButton>
              </Box>
            </Box>
            {singlePipeline.rules.length > 0 && (
              singlePipeline.rules.map((item: any) => (
                <Box
                  key={item.rule_name}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={ruleCardStyle}
                >
                  <Box sx={ruleBoxStyle}>
                    <Box>
                      <Typography variant="h6" sx={{ textAlign: 'center', margin: "1rem 0 0.5rem 0", fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>{item.rule_name}</Typography>
                      <Typography sx={{ textAlign: 'center', margin: "0.5rem 0 1rem 0", fontWeight: 400, fontSize: '1rem', color: '#292524' }}>
                        {item.rule_desc}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent='center'>
                      <Button variant="contained" sx={{
                        marginBottom: "1rem", backgroundColor: '#1A4A42',
                        color: 'white',
                        padding: '0.4rem 1.8rem',
                        fontWeight: 600,
                        borderRadius: '0.8rem',
                        fontSize: "1.2rem",
                        textTransform: 'none',
                        minWidth: '12rem',
                        '&:hover': {
                          backgroundColor: '#1B5C54',
                          color: '#fff',
                        }
                      }} data-test-id="create-rule-btn" >View Rule</Button>
                      <Button variant="contained" sx={{
                        backgroundColor: 'white',
                        color: '#1A4A42',
                        padding: '0.4rem 1.8rem',
                        fontWeight: 600,
                        fontSize: "1.2rem",
                        borderRadius: '0.8rem',
                        textTransform: 'none',
                        minWidth: '12rem',
                        "&:hover": {
                          backgroundColor: '#FFFFF0',
                        }
                      }}>Remove Rule</Button></Box>
                  </Box>
                </Box>
              )))}
          </Box>
        </Box>
        <Divider sx={innerDividerStyle} />
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524" }}>Stages ({singlePipeline.stages.length})</Typography>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: 'center', flexWrap: 'wrap' }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              sx={stageCardStyle}
            >
              <Box sx={addStageStyle}>
                <Typography variant="h5" sx={{ marginBottom: "1rem", fontSize: '1.4rem', fontWeight: 700, color: '#1A4A42' }}>New Stage</Typography>
                <Typography sx={{ textAlign: 'center', marginBottom: "1rem", fontWeight: 400, fontSize: '1.3rem', color: "#292524" }}>Add new stage to this pipeline</Typography>
                <IconButton sx={addButtonStyle} data-test-id="add-stage" onClick={()=>this.props.navigation.navigate('Cfstages1', {pipelineId: singlePipeline?.id})}>
                  <AddIcon style={{ height: "2.5rem", width: '2.5rem' }} />
                </IconButton>
              </Box>
            </Box>
            {singlePipeline.stages.length > 0 &&
              singlePipeline?.stages.map((item: any) => (
                <Box
                  key={item.id}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={stageCardStyle}
                >
                  <Box sx={ruleBoxStyle}>
                    <Box>
                      <Typography variant="h6" sx={{ textAlign: 'center', fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>{item.stage_name}</Typography>
                      <Typography variant="h6" sx={{ textAlign: 'center', fontSize: '1rem', fontWeight: 400, color: "#1A4A4280" }}>{item.id}</Typography>

                      <Typography sx={{ textAlign: 'center', fontWeight: 400, fontSize: '1rem', color: '#292524' }}>
                        {item.description}
                      </Typography>
                    </Box>
                    <Typography sx={{ textAlign: 'center', fontWeight: 700, fontSize: '1rem', color: '#292524' }}>
                      {item.deals.length} Deals in this stage
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={1}>
                      {item.deals.slice(0, 4).map((deal: any) => {
                        return <Box key={deal.deal_id} sx={{ backgroundColor: "#FFFFFF", borderRadius: '0.8rem', padding: '3rem 1.2 rem', width: '100%' }}>
                          <Typography variant="h6" sx={{ padding: '0.4rem 0', textAlign: 'center', fontSize: '1.2rem', fontWeight: 300, color: "#2C2524" }}>{deal.deal_id}</Typography>
                        </Box>
                      })}
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent='center' alignItems='center' sx={{ marginTop: "1.5rem" }}>
                      <Button variant="contained" sx={{
                        marginBottom: "1rem", backgroundColor: '#1A4A42',
                        color: 'white',
                        padding: '0.4rem 1.8rem',
                        fontWeight: 600,
                        borderRadius: '0.8rem',
                        fontSize: "1.2rem",
                        textTransform: 'none',
                        minWidth: '12rem',
                        '&:hover': {
                          backgroundColor: '#1B5C54',
                          color: '#fff',
                        }
                      }} data-test-id="view-stage-button" onClick={()=>this.handleStageView(item)}>View Stage</Button>
                    </Box>
                  </Box>
                </Box>
              ))}

          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const pipelineContainerStyle = {
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  boxSizing: "border-box",
};

const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};
const innerDividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A421A",
};
const ruleCardStyle = {
  maxWidth: '20rem',
  width: '100%',
  height: '25rem',
  margin: '1rem',
  flexBasis: '20rem',
}
const stageCardStyle = {
  maxWidth: '20rem',
  width: '100%',
  height: '35rem',
  margin: '1rem',
  flexBasis: '20rem',
};

const ruleBoxStyle = {
  borderRadius: "1.2rem",
  width: '100%',
  boxSizing: 'border-box',
  padding: '3.5rem 1.5rem',
  backgroundColor: "#1A4A424D",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: "center",
  minHeight: '100%',
  gap: '1rem'
};

const addStageStyle = {
  borderRadius: "1.2rem",
  padding: '0 1.5rem',
  backgroundColor: "#1A4A421A",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100%',
  justifyContent: "center"
}

const addButtonStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "0.8rem",
  borderRadius: "0.8rem",
  '&:hover': {
    backgroundColor: '#4D7B65',
  }
};

// Customizable Area End