import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { Box, IconButton, Button as MuiButton, Typography as MuiTypography, Divider } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TopNav from "../../navigationmenu/src/TopNav.web";
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PaginationComponent from '../../cfpipelines1/src/utils/PaginationComponent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { formatDateTimeTable } from '../../cfpipelines1/src/utils/generatePipeline';
// Customizable Area End

import Cftasklistener3Controller, {
  Props,
  configJSON,
} from "./Cftasklistener3Controller";

export default class Cftasklistener3 extends Cftasklistener3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTaskColumnTypography = (flex: number, text: string | number, field: string) => {
    const { sortField, sortOrder } = this.state;
    let sortIcon = null;
    if (sortField === field) {
      sortIcon = sortOrder === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />;
    } else {
      sortIcon = <ArrowDropDownIcon />;
    }

    return (
      <MuiTypography variant="h6" onClick={() => this.handleSortTasks(field)} style={{ fontSize: '1.4rem', flex: flex, fontWeight: 700, color: '#3E3E3E' }}>{text}{text == "Actions" ? "" : sortIcon}</MuiTypography>
    )
  }
  renderRowTypography = (flex: number, text: string | number) => {
    return (
      <MuiTypography variant="h6" data-test-id="row-typography" style={{
        fontSize: '1.4rem',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        flex: flex, fontWeight: 600, cursor: 'pointer', color: '#203447'
      }}>{text}</MuiTypography>
    )
  }
  renderTasksComponent = () => {
    const { currentPageIndex, requiredPageSize, openRows } = this.state
    const sortedRows = this.handleSortTaskData()
    const paginatedRows = sortedRows.slice(currentPageIndex * requiredPageSize, (currentPageIndex + 1) * requiredPageSize);
    const startIndex = currentPageIndex * requiredPageSize + 1;
    const endIndex = Math.min((currentPageIndex + 1) * requiredPageSize, sortedRows.length);
    const totalResults = sortedRows.length;

    return (
      <Box sx={{ ...accountGroupContainer }}>
        <TopNav navigation={this.props.navigation} id="pipelines" />
        <Divider sx={dividerStyle} />
        <Box style={{ display: "flex", marginBottom: '1.6rem', justifyContent: "space-between", alignItems: 'center', flexWrap: 'nowrap' }}>
          <MuiTypography variant="h6" sx={{ fontWeight: 600, fontSize: '2rem', color: "#292524" }}>
            Task Groups
          </MuiTypography>

          <Box display='flex' alignItems='center' gap={1}>
            <MuiButton onClick={this.handleTaskCreate} sx={{
              backgroundColor: '#1A4A42',
              color: 'white',
              padding: '0.4rem 1.6rem',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.4rem',
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: '#1B5C54',
                color: '#fff',
              }
            }}>
              Create Task Group
            </MuiButton>
            <FilterListIcon style={{ ...iconStyle, fontSize: '2rem' }} />
          </Box>
        </Box>
        <Box display="flex" flexDirection='column' gap={1}>
          <Box display="flex" style={{ backgroundColor: '#29252414', padding: '0.5rem 1rem', borderRadius: ' 0.8rem' }}>
            {this.renderTaskColumnTypography(3, 'Task Group ID', 'id')}
            {this.renderTaskColumnTypography(5, 'Name', 'name')}
            {this.renderTaskColumnTypography(2.5, 'Date Created', 'created_at')}
            {this.renderTaskColumnTypography(2.5, 'Last Edited', 'updated_at')}
            {this.renderTaskColumnTypography(6.5, 'Associated Pipelines', 'asso_pipelines')}
            {this.renderTaskColumnTypography(2.1, 'Actions', 'actions')}

          </Box>
          {paginatedRows.length > 0 ? paginatedRows.map(row => (
            <Box key={row.task_id}>
              <Box display='flex' key={row.task_id} style={{ padding: '0.5rem 1rem', borderBottom: '0.1rem solid #1A4A421A', alignItems: 'center' }}>
                {this.renderRowTypography(3, `${row.task_id}`)}
                {this.renderRowTypography(5, row.task_name)}
                {this.renderRowTypography(2.5, formatDateTimeTable(row.created_at))}
                {this.renderRowTypography(2.5, formatDateTimeTable(row.updated_at))}
                <Box style={{
                  flex: 6.5,
                  display: "flex",
                  whiteSpace: 'nowrap',
                  overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '1.4rem', fontWeight: 600, cursor: 'pointer', color: '#203447'
                }}>
                  {row.asso_pipelines.slice(0, 3).join(', ')}
                  {row.asso_pipelines.length > 3 && <p style={{ margin: 0 }}>...</p>}
                </Box>
                <Box style={{ flex: 2.1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <IconButton data-test-id="edit-btn" sx={addButtonStyle} style={{ padding: "0.6rem" }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton data-test-id="delete-btn" sx={deleteIconStyle} style={{ padding: "0.6rem" }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                  <IconButton data-test-id="delete-btn" sx={addButtonStyle} style={{ padding: "0.6rem" }} onClick={() => this.handleRowClick(row.task_id)}>
                    {openRows[row.task_id] ? <KeyboardArrowUpIcon style={{ fontSize: '1.8rem' }} /> : <KeyboardArrowDownIcon style={{ fontSize: '1.8rem' }} />}
                  </IconButton>
                </Box>
              </Box>
              {openRows[row.task_id] &&
                <Box marginTop={1} style={{ padding: '0 1rem' }} display='flex' flexDirection='column' gap={1}>
                  <Box
                    display='flex'
                    style={{ gap: '0.4rem' }}
                    flexWrap='wrap'
                  >
                    <Box style={{ flex: 2 }} display='flex' alignItems='center' gap={1}>
                      <MuiTypography
                        style={{
                          fontSize: '1.2rem',
                          fontWeight: 700,
                          color: '#203447',
                          width: '10%'
                        }}
                      >
                        Individuals
                      </MuiTypography>
                      <Box display='flex' gap={1} alignItems='center' style={{ width: '90%' }}>
                        {row.individualUser.slice(0, 4).map((u) => (
                          <Box key={u.user_name} display='inline-flex' alignItems='center' gap={1} sx={{ minWidth: '10rem', maxWidth: '10rem' }}>
                            <img
                              src={u.source}
                              alt='i,mm'
                              style={{
                                borderRadius: '50%',
                                height: '2.4rem',
                                width: '2.4rem',
                                objectFit: 'cover',
                              }}
                            />
                            <MuiTypography style={{
                              fontSize: '1rem', fontWeight: 400, color: "#292524", whiteSpace: 'normal',
                              wordWrap: 'break-word',
                              wordBreak: 'break-word',
                            }}>
                              {u.user_name}
                            </MuiTypography>
                          </Box>
                        ))}
                        <Box sx={{ border: '0.1rem solid #1A4A424D', borderRadius: '50%',height:'2.4rem', width:'2.4rem', display:"flex",alignItems:'center', justifyContent:'center' }}>
                          <MuiTypography style={{
                            fontSize: '1.1rem', fontWeight: 600, color: "#292524"
                          }}>
                          +{row.individualUser.length - 4}
                          </MuiTypography>
                        </Box>
                      </Box>
                    </Box>
                    <Box style={{ flex: 1 }} display='flex' alignItems='center' justifyContent="flex-end">
                      <MuiButton
                        variant='contained'
                        sx={{
                          backgroundColor: '#203447',
                          color: 'white',
                          padding: '0.4rem 1.6rem',
                          fontWeight: 700,
                          borderRadius: '0.8rem',
                          fontSize: '1.2rem',
                          textTransform: 'none',
                          boxShadow: 'none',
                          '&:hover': {
                            backgroundColor: '#1B5C54',
                            color: '#fff',
                          },
                        }}
                      >
                        Add New User
                      </MuiButton>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    style={{ gap: '0.4rem' }}
                    flexWrap='wrap'
                  >
                    <Box style={{ flex: 2 }} display='flex' alignItems='center' gap={1}>
                      <MuiTypography
                        style={{
                          fontSize: '1.2rem',
                          fontWeight: 700,
                          color: '#203447',
                          width: '10%'
                        }}
                      >
                        Levels:
                      </MuiTypography>
                      <Box display='flex' gap={1} alignItems='center' style={{ width: '90%' }}>
                        {row.levels.map((u) => (
                          <Box key={u} display='inline-flex' gap={1} alignItems='center'
                            sx={{
                              border: '0.1rem solid #1A4A4266',
                              backgroundColor: "white",
                              borderRadius: "0.7rem",
                              padding: "0.6rem 2rem 0.6rem 1rem",
                              fontSize: "1rem",
                              fontWeight: 400,
                              color: "#203447",
                              maxWidth: '10rem',
                              minWidth: '10rem',
                              boxSizing: 'border-box'
                            }}
                          >
                            <CheckCircleOutlineIcon style={{ fontSize: '1.6rem' }} />
                            <MuiTypography style={{ fontSize: '1rem', fontWeight: 400 }}>
                              {u}
                            </MuiTypography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box style={{ flex: 1 }} display='flex' alignItems='center' justifyContent="flex-end"></Box>
                  </Box>
                </Box>
              }
            </Box>
          )) : <Box display='flex' justifyContent='center' alignItems='center'><MuiTypography variant="h5">No tasks available. Create a new one.</MuiTypography></Box>}
        </Box>
        <PaginationComponent currentPageIndex={currentPageIndex} requiredPageSize={requiredPageSize} startIndex={startIndex} endIndex={endIndex} totalResults={totalResults} handlePageChange={this.handlePageChangeTask} />
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>{this.renderTasksComponent()}</>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const accountGroupContainer = {
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  fontFamily: "Raleway, sans-serif",
  boxSizing: "border-box",
};

const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};
const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#064E3B",
  padding: "0.6rem",
  borderRadius: "0.6rem",
};
const addButtonStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "1rem",
  borderRadius: "0.8rem",
};
const deleteIconStyle = {
  backgroundColor: "#EF444433",
  color: "#DC2626",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};

// Customizable Area End
