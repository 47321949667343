import React from "react"
import { Typography, TextField, MenuItem, FormControlLabel, Autocomplete, Radio } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'
interface DestinationAutoType {
    value: string
    handleAutoChange: (event: any, newValue: any) => void
    optionsData: string[]
}

const DestinationAutoComplete = ({ value, handleAutoChange, optionsData }: DestinationAutoType) => {
    return (
        <Autocomplete
            value={value}
            onChange={handleAutoChange}
            options={optionsData}
            getOptionLabel={(option) => option}
            sx={{
                '& .MuiOutlinedInput-root': {
                    paddingRight: '0 !important',
                },
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="value"
                    data-test-id="value"
                    fullWidth
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            padding: '0 !important',
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <SearchIcon sx={{ marginRight: '0.8rem', color: '#64748B' }} />,
                    }}
                    inputProps={{
                        sx: {
                            fontSize: '1.6rem',
                            fontWeight: 400,
                            display: 'flex',
                            alignItems: 'center'
                        },
                        ...params.inputProps,
                    }}
                    InputLabelProps={{ shrink: false }}
                />
            )}
            renderOption={(props, option, state) => {
                return (
                    <MenuItem key={option} value={option} {...props}
                        sx={{
                            fontSize: '1.6rem',
                            fontWeight: 400,
                            backgroundColor: value === option ? '#1A4A421A' : 'white',
                            '&:hover': {
                                backgroundColor: value === option ? '#1A4A421A' : '#f0f0f0',
                            },
                        }}
                    >
                        <FormControlLabel
                            value={option}
                            control={
                                <Radio
                                    checked={value === option}
                                    onChange={handleAutoChange}
                                    value={option}
                                    name="value"
                                    sx={{
                                        padding: '0 0.8rem',
                                        '&.Mui-checked': {
                                            color: '#1A4A42',
                                        },
                                        '&.MuiRadio-root': {
                                            color: '#1A4A42',
                                        },
                                        '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                                            backgroundColor: '#1A4A421A',
                                        },
                                    }}
                                />
                            }
                            label={<Typography sx={{ fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>
                                {option}
                            </Typography>}
                            sx={{
                                fontSize: '1.6rem',
                                fontWeight: '400',
                            }}
                        />
                    </MenuItem>
                );
            }}
        />
    )
}
export default DestinationAutoComplete