import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { DealType, StagesDataType, UserType, generateRandomUsers } from "../../cfpipelines1/src/utils/generatePipeline";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from 'dayjs'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    singleStage: StagesDataType
    description: string
    asso_pipeline: string
    singleDeal: DealType
    deal_name: string
    stageId: string
    due_date: Dayjs | string
    formattedDate: string
    assigned_to: UserType
    pipeline_id: string
    selectedPriority: string
    users: UserType[]
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EditCfDealDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            singleStage: { id: '', created_at: '', permission_level: '', pipeline_id: "", deals: [], description: '', rule_builder_id: null, stage_name: '', task: null, updated_at: '' },
            singleDeal: { deal_id: '', deal_name: '', deal_desc: '', due_date: dayjs(''), priority: '', created_at: '', asso_user: { source: '', user_name: '' }, pipeline_id: '', stage_id: '' },
            description: '',
            asso_pipeline: '',
            deal_name: '',
            assigned_to: { source: '', user_name: '' },
            due_date: dayjs(''),
            pipeline_id: '',
            stageId: '',
            selectedPriority: '',
            formattedDate: '',
            users: generateRandomUsers(9)
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        const editStoredDeal = JSON.parse(localStorage.getItem("deal") || "{}");
        this.setState({ singleDeal: editStoredDeal, deal_name: editStoredDeal.deal_name, asso_pipeline: editStoredDeal.pipeline_id, assigned_to: editStoredDeal.asso_user, selectedPriority: editStoredDeal.priority, description: editStoredDeal.deal_desc, due_date: dayjs(editStoredDeal.due_date), pipeline_id: editStoredDeal.pipeline_id, stageId: editStoredDeal.stage_id })
    }

    handleChange = (event: any) => {
        const { value, name } = event.target;
        this.setState((prevState) => ({...prevState,[name]:value}))
    }

    handleEditDeal = () => {
        const { deal_name, due_date, assigned_to, description, selectedPriority, singleDeal } = this.state
        const updatedDeal = { ...singleDeal };
        updatedDeal.deal_name = deal_name
        updatedDeal.deal_desc = description
        updatedDeal.priority = selectedPriority
        updatedDeal.due_date = due_date
        updatedDeal.asso_user = assigned_to
        this.setState({ singleDeal: updatedDeal })
        localStorage.setItem("deal", JSON.stringify(updatedDeal))
        toast.success("Deal edited successfully", { style: { fontSize: '1.6rem' } })
        this.props.navigation.navigate('CfDealDetails', { dealId: updatedDeal.deal_id, stageId: updatedDeal.deal_id, pipelineId: updatedDeal.pipeline_id })
    }

    isEditStageFormComplete = () => {
        const { deal_name, description, asso_pipeline, due_date, stageId, assigned_to, selectedPriority } = this.state;
        return (
            asso_pipeline && deal_name &&
            description && due_date && stageId && assigned_to && selectedPriority
        );
    };
    
    handlePriorityChange = (selectedPriority: string) => {
        this.setState({ selectedPriority })
    }

    handleDayChange = (newValue: any) => {
        this.setState({ due_date: newValue })
    }
    handleEditAssignedChanged = (userData: UserType) => {
        this.setState({ assigned_to: userData })
    }
    // Customizable Area End
}
