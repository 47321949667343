Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.updateApiMethodType = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfpipelines1";
exports.labelBodyText = "cfpipelines1 Body";

exports.btnExampleTitle = "CLICK ME";
exports.createPipelineApiEndPoint ="bx_block_cfpipelines1/pipelines"
exports.getPipelineDataEndPoint='bx_block_cfpipelines1/pipelines';
exports.stageApiEndPoint ='bx_block_cfstages1/stages'
exports.triggersApiEndPoint ='bx_block_cfpipelines1/triggers'
exports.eventsApiEndPoint ='bx_block_cfpipelines1/events'
exports.ruleBuilderApiEndPoint='bx_block_cfpipelines1/rule_builders'
// Customizable Area End