import React from "react";

// Customizable Area Start
import { View, StyleSheet } from "react-native";
import { styled } from "@mui/styles";
import { logoImage,backgroundImage } from "./assets";


const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  fontFamily:"Raleway",
  alignItems: 'center',
  height: '100vh',
  background: `url(${backgroundImage.default}) no-repeat center center fixed`,
  backgroundSize: '100vw',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.50)', // 50% opacity for the background image
    zIndex: 0,
  },
});

const LoginBox = styled('div')({
  background: 'white',
  borderRadius: '18px',
  padding: '20px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  zIndex: 1,
  width: '300px',
  textAlign: 'center',
  backgroundColor:"#1A4A424D"
});

const Logo = styled('img')({
  width: '130px',
  marginBottom: '20px',
  position:"absolute",
  top:"20px",
  left:"20px"
});

const StyledInput = styled('input')({
  width: '100%',
  padding: '8px 10px',
  marginBottom: '10px',
  border: '1px solid #ccc',
  borderRadius: '10px',
  fontSize: '16px',
});

const StyledLabel = styled('label')({
  display: 'block',
  marginBottom: '5px',
  fontSize: '16px',
  textAlign: 'left',
  fontWeight:"700",
  color:"#203447"
});

const SignInButton = styled('button')({
  width: '100%',
  padding: '10px',
  backgroundColor: 'white',
  borderRadius:"8px",
  color: '#203447',
  fontSize:"16px"
});
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Container>
        <LoginBox>
          <Logo src={logoImage.default} alt="Logo" />
          <h2 style={{color:"#1A4A42",fontWeight:"700"}}>Log In</h2>
          <form onSubmit={(event)=>this.handleLogin(event)} data-test-id="submitform">
            <StyledLabel>Email</StyledLabel>
            <StyledInput
              type="email"
              required
              data-test-id="txtInputEmail"
              value={this.state.email}
              onChange={(event)=>this.handleEmailChange(event)}
            />
            <StyledLabel>Password</StyledLabel>
            <StyledInput
              type="password"
              data-test-id="txtInputPassword"
              required
              value={this.state.password}
              onChange={(event)=>this.handlePasswordChange(event)}

            />
            <div style={{ display: 'flex',alignItems:'center' }}>
              <input
                type="checkbox"
                required
                data-test-id="inputCheckbox"
                checked={this.state.checkedRememberMe}
                onChange={(event)=>this.handleCheckBox(event)}
                style={{marginLeft:"0"}}
              />
              <p style={{ fontSize: "12px", marginLeft: "5px",textAlign:"left" }}>
                I agree with Privacy Policy and Terms and Conditions
              </p>
            </div>
            <SignInButton type="submit">
              Log In
            </SignInButton>
          </form>
        </LoginBox>
      </Container>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
