import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { TaskDataType } from "./utils/generateTasks";
import {toast} from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    singleTask: TaskDataType
    pipelinesData: { label: string }[]
    levelsData: { label: string }[]
    isDeleteTask: boolean
    // Customizable Area End
}

export interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class DetailtaskgroupController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            singleTask: { task_id: '', task_name: '', task_desc: '', created_at: '', updated_at: '', levels: [], asso_pipelines: [], individualUser: [] },
            pipelinesData: [],
            levelsData: [],
            isDeleteTask: false
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const singleTask = JSON.parse(localStorage.getItem("task") || "{}")
        if(singleTask){
        this.setState({ singleTask, pipelinesData: singleTask.asso_pipelines, levelsData: singleTask.levels })
        }
    }
    handleEditNavigate = () => {
        localStorage.setItem("task", JSON.stringify(this.state.singleTask))
        this.props.navigation.navigate("Edittaskgroup", { taskId: this.state.singleTask.task_id })
    }
    toggleDeleteTask = () => {
        this.setState(prev => ({ isDeleteTask: !prev.isDeleteTask }))
    }
    handleDeleteTask = (event: any) => {
        event.preventDefault()
        localStorage.removeItem("task")
        toast.success(`Task Group ${this.state.singleTask.task_id} deleted successfully`, { style: { fontSize: '1.6rem' } })
        this.toggleDeleteTask()
        this.props.navigation.navigate("Cftasklistener3")
    }
    sortDataByLabel = (data: { label: string }[]) => {
        return data.sort((a, b) => a.label.localeCompare(b.label));
    };
    // Customizable Area End
}
