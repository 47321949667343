import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import { Box, TextField, Button as MuiButton, Typography as MuiTypography, Divider } from '@mui/material';
// Customizable Area End

import Cfstages1Controller, {
  Props,
  configJSON,
} from "./Cfstages1Controller";

export default class Cfstages1 extends Cfstages1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={stageContainerStyle}>
        <TopNav navigation={this.props.navigation} id="pipelines" />
        <Divider sx={dividerStyle} />
        <MuiTypography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524", marginBottom: '2rem' }}>Create New Stage</MuiTypography>
        <Box component="form" noValidate autoComplete="off" onSubmit={this.handleAddStage}>
          <MuiTypography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
            Stage Name
          </MuiTypography>
          <TextField
            name="stage_name"
            data-test-id="stage-name"
            value={this.state.stage_name}
            onChange={this.handleChange}
            fullWidth
            multiline
            InputLabelProps={{ shrink: false }}
            placeholder="Type stage Name"
            style={{ marginBottom: "0.8rem" }}
            sx={{
              '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root':{
                padding:'1.5rem 0.8rem'
              }
            }}
            inputProps={{
              sx: {
                fontWeight: 400,
                fontSize: '1.6rem',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
              },
            }}
          />
          {this.state.errors.stage_name && (
            <MuiTypography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{this.state.errors.stage_name}</MuiTypography>
          )}
          <MuiTypography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
            Description
          </MuiTypography>
          <TextField
            name="description"
            data-test-id="stage-desc"
            value={this.state.description}
            onChange={this.handleChange}
            multiline
            rows={5}
            fullWidth
            InputLabelProps={{ shrink: false }}
            placeholder="Type  stage description"
            style={{ marginBottom: "0.8rem" }}
            sx={{
              '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root':{
                padding:'1rem 0.8rem'
              }
            }}
            inputProps={{
              sx: {
                fontWeight: 400,
                fontSize: '1.6rem',

              },
            }}
          />
          {this.state.errors.description && (
            <MuiTypography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{this.state.errors.description}</MuiTypography>
          )}
          <Box sx={{ ...createStageBox }}>
            <MuiButton sx={{
              width: "100%",
              padding: "1rem",
              border: "none",
              borderRadius: "0.8rem",
              fontSize: "1.6rem",
              cursor: "pointer",
              textTransform: 'none',
              fontWeight: 600,
            }} type="submit" disabled={!this.isFormComplete()} style={{
              backgroundColor: this.isFormComplete() ? "#1A4A42" : "#A8A29E66",
              color: this.isFormComplete() ? "#fff" : "#78716C",
              cursor: this.isFormComplete() ? "pointer" : "not-allowed",
            }}>Create Stage</MuiButton>
            <MuiTypography variant="h6" style={{
              alignSelf: "flex-end",
              fontSize: "1.4rem",
              color: "#737373",
              fontWeight: 400,
              fontStyle: "italic",
            }}>*You are creating a stage</MuiTypography>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const stageContainerStyle = {
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  boxSizing: "border-box",
  minHeight: 'calc(100vh - 2.5rem)'
};

const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};

const createStageBox = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  border: "0.1rem dashed #292524",
  padding: "1.5rem",
  borderRadius: "1.2rem",
  backgroundColor: "#f9f9f9",
  marginTop: "2.5rem"
}
// Customizable Area End
