import React from "react";
// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import { Box, Divider, Button, Typography, TextField, MenuItem, FormControlLabel, Radio } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// Customizable Area End

import EditStageDetailsController, {
    Props,
} from "./EditStageDetailsController";

export default class EditStageDetails extends EditStageDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { pipelinesId, stage_name, description, asso_pipeline } = this.state
        return (
            <Box sx={editStageContainerStyle}>
                <TopNav navigation={this.props.navigation} id="pipelines" />
                <Divider sx={editStageDividerStyle} />
                <Box marginBottom={2}>
                    <Box display='flex' alignItems='center' gap={1}>
                        <ArrowBackIcon style={{ fontSize: '2rem' }} onClick={this.props.navigation.goBack} />
                        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>Edit Stage</Typography>
                    </Box>
                </Box>
                <Box display='flex' flexWrap='wrap' gap={1} marginBottom={1}>
                    <Box style={{ flex: 1 }}>
                        <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                            Stage Name
                        </Typography>
                        <TextField
                            name="stage_name"
                            data-test-id="stage_name"
                            value={stage_name}
                            onChange={this.handleChange}
                            fullWidth
                            multiline
                            InputLabelProps={{ shrink: false }}
                            style={{ marginBottom: "0.8rem" }}
                            sx={{
                                '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                                    padding: '1.1rem! important',
                                }
                            }}
                            inputProps={{
                                sx: {
                                    fontWeight: 400,
                                    fontSize: '1.6rem',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                },
                            }}
                        />
                        {this.state.editStageErrors.stage_name && (
            <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{this.state.editStageErrors.stage_name}</Typography>
          )}
                    </Box>
                    <Box style={{ flex: 1 }}>
                        <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                            ASSOCIATED PIPELINE
                        </Typography>
                        <TextField
                            name="asso_pipeline"
                            data-test-id="asso_pipeline"
                            value={asso_pipeline}
                            onChange={this.handleChange}
                            select
                            fullWidth
                            style={{ marginBottom: "0.8rem" }}
                            inputProps={{
                                sx: {
                                    padding: '0.8rem !important',
                                    fontWeight: 400,
                                    fontSize: "1.6rem",
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                            }}
                            InputLabelProps={{ shrink: false }}
                            SelectProps={{
                                displayEmpty: true,

                                renderValue: (selected: unknown) => {
                                    const selectedPipeline = pipelinesId.find(item => item === selected);
                                    if (!selectedPipeline) {
                                        return <span style={{ color: "#999" }}>select</span>;
                                    }
                                    return selectedPipeline;
                                },
                            }}
                        >
                            {pipelinesId.map((item, index) => (
                                <MenuItem key={index} value={item} sx={{
                                    fontSize: '1.6rem', fontWeight: 400, backgroundColor: this.state.asso_pipeline === item ? '#1A4A421A' : 'white',
                                    '&:hover': {
                                        backgroundColor: this.state.asso_pipeline === item ? '#1A4A421A' : '#f0f0f0',
                                    },
                                }}>
                                    <FormControlLabel
                                        value={item}
                                        control={
                                            <Radio
                                                checked={asso_pipeline === item}
                                                onChange={this.handleChange}
                                                value={item}
                                                name="asso_pipeline"
                                                sx={{
                                                    padding: '0 0.8rem',
                                                    '&.Mui-checked': {
                                                        color: '#1A4A42',
                                                    },
                                                    '&.MuiRadio-root': {
                                                        color: '#1A4A42',
                                                    },
                                                    '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                                                        backgroundColor: '#1A4A421A',
                                                    },
                                                }}
                                            />
                                        }
                                        label={<Typography sx={{ fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>
                                            {item}
                                        </Typography>}
                                        sx={{
                                            fontSize: '1.6rem',
                                            fontWeight: 400
                                        }}
                                    />
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Box>
                <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                    Description
                </Typography>
                <TextField
                    name="description"
                    data-test-id="stage-desc"
                    value={description}
                    onChange={this.handleChange}
                    multiline
                    rows={5}
                    fullWidth
                    InputLabelProps={{ shrink: false }}
                    placeholder="Type  stage description"
                    style={{ marginBottom: "0.8rem" }}
                    sx={{
                        '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                            padding: '1rem 0.8rem'
                        }
                    }}
                    inputProps={{
                        sx: {
                            fontWeight: 400,
                            fontSize: '1.6rem',

                        },
                    }}
                />
                {this.state.editStageErrors.description && (
            <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{this.state.editStageErrors.description}</Typography>
          )}
                <Box sx={{ ...editStageBox }}>
                    <Button sx={{
                        width: "100%",
                        padding: "1rem",
                        border: "none",
                        borderRadius: "0.8rem",
                        fontSize: "1.6rem",
                        cursor: "pointer",
                        textTransform: 'none',
                        fontWeight: 600,
                    }} data-test-id="edit-stage-btn" onClick={this.handleEditStage} disabled={!this.isEditStageFormComplete()} style={{
                        backgroundColor: this.isEditStageFormComplete() ? "#1A4A42" : "#A8A29E66",
                        color: this.isEditStageFormComplete() ? "#fff" : "#78716C",
                        cursor: this.isEditStageFormComplete() ? "pointer" : "not-allowed",
                    }}>Save Changes</Button>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const editStageContainerStyle = {
    padding: "2rem 2rem 1rem",
    borderRadius: "1.2rem",
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
    boxSizing: "border-box",
    minHeight: 'calc(100vh - 2.5rem)'
};

const editStageDividerStyle = {
    margin: "1.5rem 0",
    backgroundColor: "#1A4A4233",
};

const editStageBox = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    border: "0.1rem dashed #292524",
    padding: "1.5rem",
    borderRadius: "1.2rem",
    backgroundColor: "#f9f9f9",
    marginTop: "2.5rem"
}

// Customizable Area End
