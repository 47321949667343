import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface UserType {
    user_name: string,
    source: string
}
interface CreateTaskErrorType {
    group_name?: string
    description?: string
}
import { generateRandomUsers, generateRandomPipelines, generateTasks, TaskDataType } from "./utils/generateTasks";
import { toast } from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    group_name: string
    taskId: string
    levels: string[]
    selectedLevel: string[]
    selectedUsers: UserType[]
    users: UserType[]
    allPipelines: string[]
    selectedPipelines: string[]
    description: string
    createErrors: CreateTaskErrorType
    // Customizable Area End
}

export interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreatetaskgroupController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            group_name: '',
            taskId: 'Auto Generated',
            levels: ["Level 1", "Level 2", "Level 3", "Level 4"],
            selectedLevel: [],
            selectedUsers: [],
            users: generateRandomUsers(7),
            allPipelines: [],
            selectedPipelines: [],
            description: '',
            createErrors: {}
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const allPipelines = generateRandomPipelines(20)
        this.setState({ allPipelines })
    }

    handleCreateTaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        this.setState((prevState) => {
            const updatedErrors = { ...prevState.createErrors };

            if (name === "group_name" && value.length <= 100) {
                delete updatedErrors.group_name;
            }

            if (name === "description" && value.length <= 300) {
                delete updatedErrors.description;
            }

            return {
                ...prevState,
                [name]: value,
                errors: updatedErrors,
            };
        });
    };

    handleChangeCreateTaskAutoComplete = (name: string) => (event: any, newValue: any) => {
        this.setState((prev) => {
            return {
                ...prev,
                [name]: newValue
            }
        });
    }
    validateTaskCreateFields = (group_name: string, task_description: string) => {
        const errors: CreateTaskErrorType = {};

        if (group_name.length > 100) {
            errors.group_name = "Task group name cannot exceed 100 characters.";
        }

        if (task_description.length > 300) {
            errors.description = "Task group description cannot exceed 300 characters.";
        }

        return errors;
    };

    handleCreateTaskGroup = () => {
        const { group_name, description, selectedLevel, selectedPipelines, selectedUsers } = this.state
        const addStageErrorMessages = this.validateTaskCreateFields(group_name, description);

        if (Object.keys(addStageErrorMessages).length > 0) {
            this.setState({ createErrors: addStageErrorMessages });
            return;
        }
        const allTaskData: TaskDataType[] = generateTasks(30)
        const updatedTaskGroup: TaskDataType = { task_id: '', task_name: '', task_desc: '', created_at: '', asso_pipelines: [], individualUser: [], levels: [], updated_at: '' };
        updatedTaskGroup.task_id = `#TG0000${allTaskData.length + 1}`
        updatedTaskGroup.task_name = group_name
        updatedTaskGroup.task_desc = description
        updatedTaskGroup.levels = selectedLevel
        updatedTaskGroup.asso_pipelines = selectedPipelines
        updatedTaskGroup.individualUser = selectedUsers
        updatedTaskGroup.created_at = new Date().toISOString()
        updatedTaskGroup.updated_at = new Date().toISOString()
        localStorage.setItem("task", JSON.stringify(updatedTaskGroup))
        toast.success("Task Created successfully", { style: { fontSize: '1.6rem' } })
        this.props.navigation.navigate('Detailtaskgroup', { taskId: updatedTaskGroup.task_id })
    }
    
    isCreateTaskComplete = () => {
        const { group_name, description, selectedLevel, selectedPipelines, selectedUsers } = this.state
        return (
            group_name &&
            description && selectedLevel.length>0 && selectedPipelines.length>0 && selectedUsers.length>0
        );
    }
    // Customizable Area End
}
