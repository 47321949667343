import React from 'react';

// Customizable Area Start
import Customtaskgroup from './utils/Customtaskgroup.web';
// Customizable Area End

import EdittaskgroupController, {
  Props,
} from './EdittaskgroupController';

export default class Edittaskgroup extends EdittaskgroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { group_name, taskId, selectedLevel, editErrors, levels, allUsers, selectedUsers, allPipelines, selectedPipelines, description } = this.state
    return (
      <Customtaskgroup
        navigation={this.props.navigation}
        id="Edit Task"
        group_name={group_name}
        taskId={taskId}
        title="Edit Task Group"
        handleChange={this.handleEditTaskChange}
        handleAutoComplete={this.handleChangeEditTaskAutoComplete}
        allLevels={levels}
        selectedLevel={selectedLevel}
        users={allUsers}
        selectedUsers={selectedUsers}
        pipelines={allPipelines}
        selectedPipelines={selectedPipelines}
        description={description}
        handleCreateEdit={this.handleEditTaskGroup}
        isCreateEditFormComplete = {this.isEditTaskComplete}
        createEditErrors={editErrors}
        buttonText="Save Changes"
      />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
