import React from "react";
// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import { Box, Divider, Button, Typography, IconButton, Modal, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formatDateTimeField} from "../../cfpipelines1/src/utils/generatePipeline";
import CloseIcon from '@mui/icons-material/Close';
import ModalButton from "./utils/ModalButton"
import DestinationAutoComplete from "./utils/DestinationAutoComplete";
// Customizable Area End

import CfDealDetailsController, {
    Props,
} from "./CfDealDetailsController";

export default class CfDealDetails extends CfDealDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { singleDeal, isDeleteDeal, isMoveDeal, dest_stage_id, stagesId } = this.state
        return (
            <Box sx={cfDealDetailContainerStyle}>
                <TopNav navigation={this.props.navigation} id="pipelines" />
                <Divider sx={cfDealDetailsDividerStyle} />
                <Box display="flex" justifyContent='space-between' marginBottom={2}>
                    <Box display='flex' alignItems='center' gap={1}>
                        <ArrowBackIcon style={{ fontSize: '2rem' }} onClick={this.props.navigation.goBack} />
                        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>{singleDeal.deal_name}</Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                        <Button data-test-id="edit-stage-btn" onClick={this.handleEditDeal} variant="contained" sx={{
                            backgroundColor: '#1A4A42',
                            color: '#FFF',
                            fontWeight: 700,
                            borderRadius: '0.8rem',
                            textTransform: 'none',
                            fontSize: '1.2rem',
                            whiteSpace: 'nowrap',
                            boxShadow: 'none',
                            width: '8rem',
                            '&:hover': {
                                backgroundColor: '#1B5C54',
                            }
                        }}>
                            Edit
                        </Button>
                        <Button data-test-id="move-stage-btn" onClick={this.toggleMoveDeal} variant="contained" sx={{
                            backgroundColor: '#FFF',
                            color: '#1A4A42',
                            fontWeight: 700,
                            borderRadius: '0.8rem',
                            textTransform: 'none',
                            fontSize: '1.2rem',
                            whiteSpace: 'nowrap',
                            border: '0.1rem solid #1A4A42',
                            width: '8rem',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: '#F5FEFD',
                            }
                        }}>
                            Move
                        </Button>
                        <Button data-test-id="delete-stage-btn" onClick={this.toggleDeleteDeal} variant="contained" sx={{
                            backgroundColor: '#EF444433',
                            color: '#DC2626',
                            fontWeight: 700,
                            borderRadius: '0.8rem',
                            textTransform: 'none',
                            fontSize: '1.2rem',
                            whiteSpace: 'nowrap',
                            boxShadow: 'none',
                            width: '8rem',
                            '&:hover': {
                                backgroundColor: '#EF444455',
                            }
                        }}>
                            Delete
                        </Button>
                    </Box>
                </Box>
                 <Box display='flex' flexDirection='column' flexWrap='wrap' gap={1} marginBottom={1}>
                    <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524" }}>Deal Details</Typography>
                    <Box display='flex' flexDirection='column' flexWrap='wrap' gap={0.5}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>DESCRIPTION</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#292524" }}>{singleDeal.deal_desc}</Typography>
                    </Box>

                </Box>
                <Box display="flex" flexWrap='wrap' marginBottom={1}>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>DEAL ID</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{singleDeal.deal_id}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>DATE CREATED</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{formatDateTimeField(singleDeal.created_at)}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>DUE DATE</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{formatDateTimeField(singleDeal.due_date)}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>ASSIGNED TO</Typography>
                        <Box
                        display="flex"
                        alignItems="center"
                        gap={0.5}
                      >
                        <img
                          src={singleDeal.asso_user.source}
                          alt="user image"
                          style={{
                            borderRadius: '50%',
                            height: '2.4rem',
                            width: '2.4rem',
                            objectFit: 'cover',
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 400, fontSize: '1.4rem', color: '#203447' }}
                        >
                          {singleDeal.asso_user.user_name}
                        </Typography>
                      </Box>
                    </Box>
                </Box>
                <Box display="flex" flexWrap='wrap'>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>PRIORITY</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{singleDeal.priority}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>ASSOCIATED PIPELINE</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{singleDeal.pipeline_id}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>ASSOCIATED STAGE</Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{singleDeal.stage_id}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                    </Box>
                </Box>

                <Modal
                    open={isMoveDeal}
                    onClose={this.toggleMoveDeal}
                    aria-labelledby="add-stage-modal"
                    aria-describedby="add-stage-form"
                >
                    <Box sx={cfDealDetailsModalStyle}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <Typography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '2rem' }}>Move Stage {singleDeal.deal_id}</Typography>
                            <IconButton onClick={this.toggleMoveDeal}>
                                <CloseIcon style={{ height: "2rem", width: '2rem' }} />
                            </IconButton>
                        </Box>

                        <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem", padding: 0, width: '100%' }} />

                        <Box component="form" noValidate autoComplete="off" onSubmit={this.handleMoveDeal}>
                            <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#64748B", fontWeight: 700, fontSize: '1.4rem' }}>
                                Source Stage ID
                            </Typography>
                            <TextField
                                data-test-id="stage_id"
                                value={singleDeal.stage_id}
                                fullWidth
                                disabled
                                aria-readonly
                                InputLabelProps={{ shrink: false }}
                                style={{ marginBottom: "0.8rem" }}
                                inputProps={{
                                    readOnly: true,
                                    sx: {
                                        padding: '0.8rem',
                                        fontWeight: 400,
                                        fontSize: '1.6rem',
                                    },
                                }}
                            />
                            <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#64748B", fontWeight: 700, fontSize: '1.4rem' }}>
                                Destination Stage ID
                            </Typography>
                            <DestinationAutoComplete value={dest_stage_id} optionsData={stagesId} handleAutoChange={this.handleChangeMoveDeal} />
                            <ModalButton contentDirection="flex-end" toggleModal={this.toggleMoveDeal} text="Done" />
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    open={isDeleteDeal}
                    onClose={this.toggleDeleteDeal}
                    aria-labelledby="delete-stage-modal"
                    aria-describedby="delete-stage-form"
                >
                    <Box sx={cfDealDetailsModalStyle}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <Typography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '2rem' }}>Delete Stage {singleDeal.deal_id}</Typography>
                            <IconButton onClick={this.toggleDeleteDeal}>
                                <CloseIcon style={{ height: "2rem", width: '2rem' }} />
                            </IconButton>
                        </Box>

                        <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem", padding: 0, width: '100%' }} />
                        <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="h6" style={{ fontSize: '1.2rem' }}>{`Are you sure want to delete stage: ${singleDeal.deal_id} ?`}</Typography>
                        </Box>
                        <Box component="form" noValidate autoComplete="off" onSubmit={this.handleDeleteDeal}>
                            <ModalButton contentDirection="center" toggleModal={this.toggleDeleteDeal} text="Delete" />
                        </Box>
                    </Box>
                </Modal>

            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const cfDealDetailContainerStyle = {
    padding: "2rem 2rem 1rem",
    borderRadius: "1.2rem",
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
    boxSizing: "border-box",
    minHeight: 'calc(100vh - 2.5rem)'
};

const cfDealDetailsDividerStyle = {
    margin: "1.5rem 0",
    backgroundColor: "#1A4A4233",
};

const cfDealDetailsModalStyle = {
    position: 'absolute',
    bgcolor: 'background.paper',
    boxShadow: 2,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.8rem',
    p: 3,
    width: '40rem',
};

// Customizable Area End
