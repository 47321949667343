import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { generatePipeline, PipelineType, StagesDataType } from './utils/generatePipeline';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pipelineData: PipelineType[]
  pipeline_id: string | null
  singlePipeline: PipelineType
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PipelineDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pipelineData: [],
      pipeline_id: '',
      singlePipeline: {
        id: '', pipeline_name: '',
        description: '',
        created_at: '',
        updated_at: '',
        permission_level: '',
        pipeline_serial_id: '',
        status: '',
        order: null,
        stages: [],
        clinic_name: '',
        visibleBy: { IndividualUsers: [], PermissionLevels: [], TaskGroups: [{ task_name: 'task 1' }] },
        rules: []
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const pipelinesRandom = generatePipeline(36)
    this.setState({ pipelineData: pipelinesRandom })
    this.getPipelineIdFromUrl()
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.pipeline_id !== this.state.pipeline_id) {
      const pipelineDetail = this.state.pipelineData.find(pipeline => pipeline.id === this.state.pipeline_id)
      if (pipelineDetail) { this.setState({ singlePipeline: pipelineDetail }) } else {
        const storedPipeline = JSON.parse(localStorage.getItem('pipeline') || "{}")
        this.setState({ singlePipeline: storedPipeline })
      }
    }
  }

  getPipelineIdFromUrl = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const pipeline_id = queryParams.get('pipelineId');
    this.setState({ pipeline_id });
  }

  handleStageView = (item: StagesDataType) => {
    localStorage.setItem("stage", JSON.stringify(item));
    this.props.navigation.navigate("Cfstage1details", { stageId: item.id, pipelineId: this.state.pipeline_id })
  }

  // Customizable Area End
}
