import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { generatePipeline, PipelineType } from './utils/generatePipeline';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currentPage: number,
  pageSize: number,
  pipelineData: PipelineType[]
  rowData: PipelineType[],
  filteredData: PipelineType[],
  pipeline_id: number | null | string
  pipeline_name: string
  isDeletePipelineModal: boolean,
  searchQuery: string
  isFilterModal: boolean
  sortBy: string
  sortDirection: string;
  isPipelineDuplicateModal: boolean
  singlePiplineDuplicate: PipelineType
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfpipelines1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currentPage: 0,
      pageSize: 10,
      pipelineData: [],
      rowData: [],
      filteredData: [],
      pipeline_id: null,
      pipeline_name: '',
      isDeletePipelineModal: false,
      searchQuery: '',
      isFilterModal: false,
      sortBy: '',
      sortDirection: '',
      isPipelineDuplicateModal: false,
      singlePiplineDuplicate: { id: '', created_at: '', description: '', order: null, permission_level: '', pipeline_name: '', pipeline_serial_id: '', status: '', updated_at: '', stages: [], clinic_name: '', rules: [], visibleBy: { IndividualUsers: [], PermissionLevels: [], TaskGroups: [] } }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    const pipelines = generatePipeline(36);
    this.setState({ filteredData: pipelines, rowData: pipelines })
  }
  onPageChange = (page: number) => this.setState({ currentPage: page })
  handlePrevious = () => {
    if (this.state.currentPage > 0) this.onPageChange(this.state.currentPage - 1);
  };
  handlePageChange = (event: any, value: number) => {
    this.setState({ currentPage: value - 1 });
  };

  handleNext = (totalPages: number) => {
    if (this.state.currentPage < totalPages - 1) this.onPageChange(this.state.currentPage + 1);
  };

  handlePipelineNavigate = (id: number | string) => {
    if (this.props.navigation.navigate) {
      this.props.navigation.navigate('PipelineDetail', { pipelineId: id })
    }
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({ isDeletePipelineModal: !prevState.isDeletePipelineModal }))
  }
  handleDeleteToggle = (id: number | string, pipeline_name: string) => {
    this.toggleDeleteModal()
    this.setState({ pipeline_id: id, pipeline_name })
  }
  toggleDuplicateModal = () => {
    this.setState(prevState => ({ isPipelineDuplicateModal: !prevState.isPipelineDuplicateModal }))
  }
  handleDuplicateToggle = (pipeline: PipelineType) => {
    this.toggleDuplicateModal()
    this.setState({ singlePiplineDuplicate: pipeline })
  }

  handleDeletePipeline = (event: any) => {
    event.preventDefault()
    const filteredRowData = this.state.filteredData.filter(item => item.id !== this.state.pipeline_id)
    this.setState({ filteredData: filteredRowData, isDeletePipelineModal: false })
    toast.success("Pipeline Deleted Successfully", { style: { fontSize: "1.6rem" } })
  }
  generateSecureId = () => {
    const uuidNumber = parseInt(uuidv4().replace(/\D/g, '').slice(0, 7), 10);

    const formattedId = `#P${uuidNumber.toString().padStart(7, '0')}`;

    return formattedId;
  };

  handleDuplicatePipeline = () => {
    const { singlePiplineDuplicate } = this.state;

    let newId: string;
    do {
      newId = this.generateSecureId();
    } while (this.state.rowData.some(pipeline => pipeline.id === newId));

    const newPipeline: PipelineType = {
      ...singlePiplineDuplicate,
      id: newId,
    };

    this.setState((prevState: any) => ({
      filteredData: [...prevState.rowData, newPipeline],
      isPipelineDuplicateModal: false,
    }));
    toast.success("Pipeline Duplicated Successully", { style: { fontSize: '1.6rem' } })
  };


  handleCreatePipeline = () => {
    this.props.navigation.navigate("CreatePipeline")
  }
  handleSearchPipeline = (event: any) => {
    const query = event.target.value
    this.setState({ searchQuery: query });
    const { rowData } = this.state
    if (query === '') {
      this.setState({ filteredData: rowData });
    }
    else {
      const filtered = rowData.filter(pipeline =>
        pipeline.pipeline_name.toLowerCase().includes(query.toLowerCase()) ||
        pipeline.id.toString().includes(query.toUpperCase()) ||
        pipeline.clinic_name.toLowerCase().includes(query.toLowerCase())
      );

      this.setState({ filteredData: filtered });
    }
  };
  toggleFilterModal = () => {
    this.setState(prevState => ({ isFilterModal: !prevState.isFilterModal }))
  }

  handleSortDirectionChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    this.setState({ sortDirection: newValue });
  };

  handleSortFieldChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    this.setState({ sortBy: newValue, sortDirection: '' });
  };

  handleSortChange = () => {
    const { sortDirection, rowData, sortBy } = this.state;
    const sortedData = [...rowData].sort((a, b) => {
      let comparison = 0;

      if (sortBy === 'id') {
        comparison = this.getNumericId(a.id) - this.getNumericId(b.id);
      } else if (sortBy === 'name') {
        comparison = a.pipeline_name.localeCompare(b.pipeline_name);
      } else if (sortBy === 'created_at' || sortBy === 'updated_at') {
        const dateA = new Date(a[sortBy]).getTime();
        const dateB = new Date(b[sortBy]).getTime();
        comparison = dateA - dateB;
      } else if (sortBy === 'asso_clinic') {
        comparison = a.clinic_name.localeCompare(b.clinic_name);
      }

      return sortDirection === 'asc' ? comparison : -comparison;
    });

    this.setState({
      filteredData: sortedData,
      isFilterModal: false,
    });
  };


  getNumericId = (formattedId: any): number => {
    const match = formattedId.match(/\d+/);
    return match ? Number(match[0]) : NaN;
  };

  getSortLabel = (direction: 'asc' | 'desc') => {
    const { sortBy } = this.state;

    if (sortBy === 'created_at' || sortBy === 'updated_at') {
      return direction === 'asc' ? 'Oldest' : 'Newest';
    }

    if (sortBy === 'name') {
      return direction === 'asc' ? 'A-Z' : 'Z-A';
    }

    if (sortBy === 'id') {
      return direction === 'asc' ? 'Lowest' : 'Highest';
    }

    return direction === 'asc' ? 'Ascending' : 'Descending';
  };
  // Customizable Area End
}
